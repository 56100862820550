/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Sprite, Text, Container, Graphics } from '@inlet/react-pixi'
import useDeviceOrientation from '../../hooks/useWindowOrientation'
import usePixiCanvasSize from '../../hooks/usePixiCanvasSize'

const GameHelp = ({ text, btnOkTxt, onOkClick, btnOutTxt, onOutClick }) => {
  const { width, height } = usePixiCanvasSize()
  const draw = useCallback(
    (g) => {
      g.clear()
      g.lineStyle(5, 0x5500ff, 0.9)
      g.beginFill(0x8800cc, 0.8)
      g.drawRoundedRect(
        width * 0.1,
        height * 0.1,
        width * 0.8,
        height * 0.8,
        15
      )
      g.endFill()
    },
    [width, height]
  )
  const drawB1 = useCallback(
    (g) => {
      g.clear()
      g.lineStyle(5, 0x5500ff, 0.9)
      g.beginFill(0x00aa55, 0.8)
      g.drawRoundedRect(
        width * 0.15,
        height * 0.7,
        width * 0.3,
        height * 0.1,
        15
      )
      g.endFill()
    },
    [width, height]
  )
  const drawB2 = useCallback(
    (g) => {
      g.clear()
      g.lineStyle(5, 0x5500ff, 0.9)
      g.beginFill(0xaa0055, 0.8)
      g.drawRoundedRect(
        width * 0.55,
        height * 0.7,
        width * 0.3,
        height * 0.1,
        15
      )
      g.endFill()
    },
    [width, height]
  )
  return (
    <Container>
      <Graphics draw={draw} />
      <Graphics
        draw={drawB1}
        interactive={true}
        click={onOkClick}
        touchstart={onOkClick}
      >
        <Text
          anchor={0.5}
          y={height * 0.75}
          x={width * 0.3}
          text={btnOkTxt}
          style={{
            fontFamily: "'Slackey', cursive",
            fontSize: Math.min(height, width) * 0.04,
            wordWrap: true,
            wordWrapWidth: width * 0.3,
            align: 'center',
          }}
        />
      </Graphics>
      <Graphics
        draw={drawB2}
        interactive={true}
        click={onOutClick}
        touchstart={onOutClick}
      >
        <Text
          anchor={0.5}
          y={height * 0.75}
          x={width * 0.7}
          text={btnOutTxt}
          style={{
            fontFamily: "'Slackey', cursive",
            fontSize: Math.min(height, width) * 0.04,
            wordWrap: true,
            wordWrapWidth: width * 0.3,
            align: 'center',
          }}
        />
      </Graphics>
      <Text
        anchor={0.5}
        y={height * 0.4}
        x={width * 0.5}
        text={text}
        style={{
          fontFamily: "'Slackey', cursive",
          fontSize: Math.min(height, width) * 0.08,
          wordWrap: true,
          wordWrapWidth: width * 0.7,
          align: 'center',
        }}
      />
    </Container>
  )
}

GameHelp.propTypes = {
  text: PropTypes.string,
  btnOkTxt: PropTypes.string,
  onOkClick: PropTypes.func,
  btnOutTxt: PropTypes.string,
  onOutClick: PropTypes.func,
}
export default GameHelp
