/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Sprite, Text, Container, Graphics } from '@inlet/react-pixi'
import useDeviceOrientation from '../../hooks/useWindowOrientation'
import usePixiCanvasSize from '../../hooks/usePixiCanvasSize'

const StartHelp = ({ isDone }) => {
  const { t } = useTranslation()
  const orientation = useDeviceOrientation()
  const timeoutRef = useRef()
  const [showMessage, setShowMessage] = useState()
  const [rotateIsDone, setRotateIsDone] = useState(false)
  const { width, height } = usePixiCanvasSize()

  useEffect(() => {
    if (orientation === 'landscape' && !rotateIsDone && !timeoutRef.current) {
      setRotateIsDone(true)
      setShowMessage(true)
      isDone()
      // timeoutRef.current = setTimeout(() => {
      //   timeoutRef.current = undefined
      //   setShowMessage(false)
      //   isDone()
      // }, 2000)
    }
  }, [rotateIsDone, orientation])

  // useEffect(() => {
  //   return () => {
  //     if (timeoutRef.current) {
  //       clearTimeout(timeoutRef.current)
  //     }
  //   }
  // }, [])

  const draw = useCallback(
    (g) => {
      g.clear()

      g.beginFill(0x000000, 0.85)
      g.moveTo(0, 0)
      g.lineTo(0, height)
      g.lineTo(width, height)
      g.lineTo(width, 0)
      g.lineTo(0, 0)
      g.endFill()
    },
    [width, height]
  )

  return (
    <Container>
      <Graphics
        draw={draw}
        /* interactive={true}
        click={() => {
          if (showMessage) {
            setShowMessage(false)
            isDone()
          }
        }}
        touchstart={() => {
          if (showMessage) {
            setShowMessage(false)
            isDone()
          }
        }} */
      >
        {!rotateIsDone && (
          <>
            <Sprite
              image="/imgs/rotateDevice.png"
              scale={{ x: 0.5, y: 0.5 }}
              anchor={0.5}
              x={width * 0.5}
              y={height * 0.4}
            />
            <Text
              anchor={0.5}
              x={width * 0.5}
              y={height * 0.65}
              text={t('Game1.RotateDevice')}
              style={{
                fontFamily: "'Orbitron', sans-serif",
                //   wordWrap: true,
                align: 'center',
                fill: '#ffffff',
              }}
            />
          </>
        )}
        {showMessage && (
          <>
            <Sprite
              image="/imgs/soundOn.png"
              scale={{ x: 0.5, y: 0.5 }}
              anchor={0.5}
              x={width * 0.5}
              y={height * 0.4}
            />
            <Text
              anchor={0.5}
              x={width * 0.5}
              y={height * 0.65}
              text={t('Game1.SoundOn')}
              style={{
                fontFamily: "'Orbitron', sans-serif",
                //   wordWrap: true,
                align: 'center',
                fill: '#ffffff',
              }}
            />
            <Text
              anchor={0.5}
              x={width * 0.5}
              y={height * 0.75}
              text={t('Game1.LoadingAssets')}
              style={{
                fontFamily: "'Orbitron', sans-serif",
                //   wordWrap: true,
                align: 'center',
                fill: '#ffffff',
              }}
            />
          </>
        )}
      </Graphics>
    </Container>
  )
}

StartHelp.propTypes = {
  isDone: PropTypes.func,
}

export default StartHelp
