import React, { useState, useEffect, useCallback } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  useTick,
  Container,
  useApp,
  TilingSprite,
  Sprite,
  Text,
} from '@inlet/react-pixi'
import Baby from './Baby'
import GameBoard from './GameBoard'
import usePixiCanvasSize from '../../hooks/usePixiCanvasSize'
// eslint-disable-next-line no-unused-vars
import * as PIXI_SOUND from '@pixi/sound' // This is still necessary!
import * as PIXI from 'pixi.js'

const numEnemies = 20
const speed = 4
const babyDim = {
  width: 100,
  height: 50,
}

const enemyTypes = [
  'ambulance',
  'dryer',
  'emptyPlate',
  'sucker',
  'brocoli',
  'shoe',
]
const randomSprite = () => {
  const item = enemyTypes[Math.round(Math.random() * (enemyTypes.length - 1))]
  return {
    img: `/imgs/game1/level1/${item}.png`,
    name: item,
    sound: `/sounds/${item}.{mp3.wav}`,
  }
}

const Game = ({ start, onExit, onRestart, onLoaded }) => {
  const { t } = useTranslation()
  const { width, height } = usePixiCanvasSize()
  const [action, setAction] = useState('lying')
  const [bkgX, setBkgX] = useState(0)
  const [groundY, setGroundY] = useState(194)
  const [scale, setScale] = useState(1)
  const [characterLocked, setCharacterLocked] = useState(false)
  const [enemyX, setEnemyX] = useState(width * 0.7)
  const [enemiesSrcs] = useState(
    [...Array(numEnemies).keys()].map((k) => {
      const sprite = randomSprite()
      return {
        key: k,
        img: sprite.img,
        sound: sprite.sound,
        name: sprite.name,
      }
    })
  )
  const [counter, setCounter] = useState(0)
  const [enemies, setEnemies] = useState([])
  const [hit, setHit] = useState(false)
  const [points, setPoints] = useState(0)
  const [lives, setLives] = useState(4)
  const [gameOver, setGameOver] = useState(false)
  const [ended, setEnd] = useState(false)
  const [damagers, setDamagers] = useState([])
  const [startedMoving, setStartedMoving] = useState(false)
  const [sounds, setSounds] = useState()
  const [loader] = useState(new PIXI.Loader())
  const app = useApp()

  const containerRef = useCallback((c) => {
    if (c !== null) {
      setEnemies(c.children)
    }
  }, [])

  useEffect(() => {
    loader.add('music', '/sounds/game1Bkg.mp3')
    loader.add('damage', '/sounds/damage.mp3')
    loader.add('loose', '/sounds/loose.wav')
    loader.add('win', '/sounds/win.wav')
    enemyTypes.forEach((e) => {
      loader.add(e, `/sounds/${e}.mp3`)
    })
    loader.load(function (loader, resources) {
      setSounds(resources)
      console.log('sounds loaded')
      onLoaded()
    })
  }, [])

  useEffect(() => {
    if (gameOver || ended) {
      Object.keys(sounds).forEach((k) => sounds[k].sound.stop())
    }
    if (gameOver) {
      sounds.loose.sound.play()
    } else if (ended) {
      sounds.win.sound.play()
    }
  }, [gameOver, ended])

  useEffect(() => {
    if (startedMoving && sounds) {
      console.log(sounds)
      sounds.music.sound.volume = 0.2
      sounds.music.sound.play({ loop: true, singleInstance: true })
      return () => {
        sounds.music.sound.stop()
      }
    }
    // sound.play('music')
  }, [startedMoving, sounds])

  useEffect(() => {
    if (height < 500) {
      setScale(0.4)
      setGroundY((g) => g * 0.4)
    } else {
      setScale(1)
      setGroundY(194)
    }
  }, [height])

  useTick((delta) => {
    if (!gameOver && !ended && start) {
      if (characterLocked) {
        if (action === 'crawlRight') {
          setBkgX((x) => x - delta * speed)
          setEnemyX((x) => x - delta * speed)
        }
      }
      setCounter((c) => c + 0.02 * delta)
      if (hit === false) {
        enemies
          .filter((e) => e.visible === true)
          .some((e) => {
            if (
              e.x < width / 2 &&
              e.x + e.width > width / 2 - babyDim.width * 0.3 &&
              e.y > height - groundY - babyDim.height
            ) {
              setHit(true)
              sounds.damage.sound.play()
              const myIdx = enemies.indexOf(e)
              if (!damagers.includes(myIdx)) {
                setDamagers([...damagers, myIdx])
              }
              if (lives <= 1) {
                setGameOver(true)
              }
              setLives((l) => l - 1)
              return true
            }
          })
      }
      enemies
        .filter(
          (e) =>
            e.visible === true &&
            e.x < width * 0.67 &&
            e.x + e.width > width * 0.33
        )
        .forEach((e) => {
          const myIdx = enemies.indexOf(e)
          const soundName = enemiesSrcs[myIdx].name
          if (e.x > width * 0.65 && sounds[soundName].sound.isPlaying) {
            sounds[soundName].sound.stop()
          } else if (e.x > width * 0.6 && !sounds[soundName].sound.isPlaying) {
            sounds[soundName].sound.play({ loop: true, singleInstance: true })
          } else if (e.x < width * 0.3 && sounds[soundName].sound.isPlaying) {
            sounds[soundName].sound.stop()
          } else {
            sounds[soundName].sound.volume =
              ((width * 0.3 - Math.abs(width / 2 - e.x)) / (width * 0.3)) * 0.4
          }
        })
    }
    const newPoints = enemies.filter(
      (e, idx) => e.visible === false && e.x < 0 && !damagers.includes(idx)
    ).length
    if (points !== newPoints) {
      setPoints(newPoints)
    }
    if (enemies.filter((e) => e.x < 0).length >= numEnemies) {
      setEnd(true)
    }
  })

  useEffect(() => {
    if (hit) {
      const timeout = setTimeout(() => {
        setHit(false)
      }, 2000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [hit])

  const handleLeft = () => {
    if (!startedMoving) setStartedMoving(true)
    setAction('crawlLeft')
  }

  const handleRight = () => {
    if (!startedMoving) setStartedMoving(true)
    setAction('crawlRight')
  }

  const handleKeyUp = (e) => {
    handleStop()
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 39) {
      // Right
      handleRight()
    } else if (e.keyCode === 37) {
      // Left
      handleLeft()
    } else if (e.keyCode === 38) {
      // Up
    } else if (e.keyCode === 40) {
      // Down
    }
  }
  const handleStop = () => {
    setAction('lying')
  }
  const handleTouchEnd = (e) => {
    handleStop()
  }

  const handleTouchStart = (e) => {
    const clientX = e.touches[0].clientX
    if (clientX < width * 0.5) {
      handleLeft()
    } else {
      handleRight()
    }
  }

  useEffect(() => {
    if (start) {
      window.addEventListener('keydown', handleKeyDown, false)
      window.addEventListener('keyup', handleKeyUp, false)
      app.renderer.view.addEventListener('touchstart', handleTouchStart, false)
      app.renderer.view.addEventListener('touchend', handleTouchEnd, false)

      return () => {
        window.removeEventListener('keydown', handleKeyDown, false)
        window.removeEventListener('keyup', handleKeyUp, false)
        if (app?.renderer?.view) {
          app.renderer.view.removeEventListener(
            'touchstart',
            handleTouchStart,
            false
          )
          app.renderer.view.removeEventListener(
            'touchend',
            handleTouchEnd,
            false
          )
        }
      }
    }
  }, [start, app.renderer.view])

  return (
    <Container>
      <TilingSprite
        image={'/imgs/game1/level1/layer06_sky.png'}
        width={width}
        height={height}
        tilePosition={{ x: bkgX / 8, y: height }}
        tileScale={scale}
      />
      <TilingSprite
        image={'/imgs/game1/level1/layer05_rocks.png'}
        width={width}
        height={height}
        tilePosition={{ x: bkgX / 4, y: height }}
        tileScale={scale}
      />
      <TilingSprite
        image={'/imgs/game1/level1/layer04_clouds.png'}
        width={width}
        height={height}
        tilePosition={{ x: bkgX / 16, y: height }}
        tileScale={scale}
      />
      <TilingSprite
        image={'/imgs/game1/level1/layer03_trees.png'}
        width={width}
        height={height}
        tilePosition={{ x: bkgX / 2, y: height }}
        tileScale={scale}
      />
      <Sprite
        image={'/imgs/game1/stickPanel.png'}
        anchor={{ x: 0.5, y: 1 }}
        x={enemyX - width * 0.2}
        y={height - groundY}
        scale={scale * 1.25}
      />
      <Text
        anchor={0.5}
        y={height - groundY - 180 * scale * 1.25}
        x={enemyX - width * 0.2}
        text={t('Game1.Level1Msg')}
        style={{
          fontFamily: "'Slackey', cursive",
          fontSize: Math.min(height, width) * 0.03,
          wordWrap: true,
          wordWrapWidth: 190 * scale * 1.25,
          align: 'center',
        }}
      />
      <TilingSprite
        image={'/imgs/game1/level1/layer01_ground.png'}
        width={width}
        height={height}
        tilePosition={{ x: bkgX, y: height }}
        tileScale={scale}
      />
      <Container ref={containerRef}>
        {enemiesSrcs.map((e) => (
          <Sprite
            visible={
              enemyX + (width / 3) * e.key > 0 &&
              enemyX + (width / 3) * e.key < width
            }
            key={e.key}
            image={e.img}
            x={enemyX + (width / 3) * e.key}
            y={
              height -
              groundY -
              (Math.abs(Math.cos((counter + e.key) * (e.key + 1) * 0.2)) *
                (height - groundY)) /
                2
            }
            anchor={{ x: 0.5, y: 1 }}
            scale={scale / 2}
            // ref={loadEnemies}
          />
        ))}
      </Container>
      {start && (
        <Baby
          initX={width * 0.05}
          initY={height - groundY}
          action={gameOver ? 'lying' : ended ? 'sit' : action}
          onLocked={(val) => setCharacterLocked(val)}
          hit={hit}
        />
      )}
      <Text
        anchor={0.5}
        y={height * 0.1}
        x={width * 0.85}
        text={`${t('Game1.Points')}: ${points}`}
        style={{
          fontFamily: "'Slackey', cursive",
          fontSize: Math.min(height, width) * 0.05,
          align: 'left',
        }}
      />
      {[...Array(lives).keys()].map((k) => (
        <Sprite
          key={k}
          image={'/imgs/game1/heart.png'}
          anchor={0.5}
          x={width * 0.8 + k * scale * 50}
          y={height * 0.15}
          scale={scale / 4}
        />
      ))}
      {(gameOver || ended) && (
        <GameBoard
          text={
            gameOver
              ? t('Game1.YouLoose')
              : t('Game1.YouWin', { points: points })
          }
          btnOkTxt={t('Game1.PlayAgain')}
          btnOutTxt={t('Game1.GoBack')}
          onOkClick={() => onRestart()}
          onOutClick={() => onExit()}
        />
      )}
    </Container>
  )
}

Game.propTypes = {
  start: PropTypes.bool,
  onExit: PropTypes.func,
  onRestart: PropTypes.func,
  onLoaded: PropTypes.func,
}
export default Game
