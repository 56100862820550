/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Tween, ScrollTrigger } from 'react-gsap'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import AnimatedPicture from '../components/birthday/AnimatedPicture'
import { gsap } from 'gsap'
import { useHistory } from 'react-router-dom'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { Page } from '../components/common/styles'
import { AuthContext } from '../contexts/authContext'
import SvgCake from '../components/svgs/Cake'
import styled from 'styled-components'
import {
  Panel,
  PageHeader,
  GameHeader,
  TextBox,
  Container,
  JoinButton,
  BigTextBox,
} from '../components/birthday/PanelStyles'
import BikeAnimation from '../components/birthday/BikeAnimation'
import CakeAnimation from '../components/birthday/CakeAnimation'

const FirstBirthday = () => {
  const { t } = useTranslation()
  const { authState } = useContext(AuthContext)
  const history = useHistory()
  const [cakeReady, setCakeReady] = useState(false)
  const [playAnimation, setPlayAnimation] = useState('play')

  gsap.registerPlugin(MotionPathPlugin)

  return (
    <Page id="page">
      {/* <React.Fragment> */}
      <div
        id="arrow"
        style={{
          zIndex: '10',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'absolute',
          bottom: '2.5%',
          color: 'white',
          width: '100%',
        }}
      >
        <Tween repeat="-1" yoyo={true} to={{ y: '0.5em' }}>
          <KeyboardArrowDownIcon style={{ fontSize: '3em' }} />
        </Tween>
      </div>
      <Panel id="id1" bkgColor="#2c7ad2">
        <PageHeader>
          {t('Birthday.Header', { name: authState.usrAttr.profile.name })}
        </PageHeader>
      </Panel>
      <Panel id="netherlands" bkgColor="#60bedb" direction="column">
        <GameHeader>{t('Birthday.Pane1.Title')}</GameHeader>
        <Container style={{ flexDirection: 'column' }}>
          <TextBox
            style={{
              paddingRight: '5%',
              width: '100%',
              maxWidth: '90%',
              marginTop: '0px',
            }}
          >
            {t('Birthday.Pane1.Msg')}
          </TextBox>
          <BikeAnimation trigger="#netherlands" />
        </Container>
      </Panel>
      <Panel id="menace" bkgColor="#dac5ee" direction="column">
        <GameHeader>{t('Birthday.Pane2.Title')}</GameHeader>
        <Container>
          <TextBox maxWidthLandscape="70%">{t('Birthday.Pane2.Msg')}</TextBox>
          <AnimatedPicture
            src="/imgs/personal/arturoTheMenace.png"
            frames={6}
            trigger="#menace"
          />
        </Container>
      </Panel>
      <Panel id="glasses" bkgColor="#e77614" direction="column">
        <GameHeader>{t('Birthday.Pane3.Title')}</GameHeader>
        <Container>
          <AnimatedPicture
            src="/imgs/personal/coolGlasses.png"
            frames={3}
            trigger="#glasses"
          />
          <TextBox>{t('Birthday.Pane3.Msg')}</TextBox>
        </Container>
      </Panel>
      <Panel id="dj" bkgColor="#cedf87" direction="column">
        <GameHeader>{t('Birthday.Pane4.Title')}</GameHeader>
        <Container>
          <TextBox maxWidthLandscape="70%">{t('Birthday.Pane4.Msg')}</TextBox>
          <AnimatedPicture
            src="/imgs/personal/djArturo.png"
            frames={2}
            trigger="#dj"
          />
        </Container>
      </Panel>
      <Panel id="cake" bkgColor="#4bce67">
        <GameHeader>{t('Birthday.Pane5.Title')}</GameHeader>
        <Container style={{ flexDirection: 'column' }}>
          <CakeAnimation trigger="#cake" active={cakeReady} />
          {/* <TextBox>{`Let them have cake.`}</TextBox> */}
        </Container>
      </Panel>
      <Panel id="join" bkgColor="#17ffc5">
        <BigTextBox>{t('Birthday.JoinPane.Msg')}</BigTextBox>
        <PageHeader>{t('Birthday.JoinPane.Banner')}</PageHeader>
        <Tween
          repeat="-1"
          yoyo={true}
          to={{ scale: 1.2 }}
          playState={playAnimation}
        >
          <JoinButton
            onClick={() => history.push('/events/1')}
            onMouseOver={() => setPlayAnimation('pause')}
            onMouseLeave={() => setPlayAnimation('resume')}
          >
            {t('Birthday.JoinPane.Btn')}
          </JoinButton>
        </Tween>
      </Panel>
      <ScrollTrigger
        trigger="#id1"
        start="top top"
        end="bottom -300%"
        endTrigger="#join"
        scrub={0.5}
        // markers
        pin="#arrow"
      ></ScrollTrigger>
      <ScrollTrigger
        trigger="#netherlands"
        start="top top"
        end="bottom top"
        scrub={0.5}
        // markers
        pin="#netherlands"
      ></ScrollTrigger>
      <ScrollTrigger
        trigger="#menace"
        start="top top"
        end="bottom top"
        scrub={0.5}
        // markers
        pin="#menace"
      ></ScrollTrigger>
      <ScrollTrigger
        trigger="#glasses"
        start="top top"
        end="bottom top"
        scrub={0.5}
        // markers
        pin="#glasses"
      ></ScrollTrigger>
      <ScrollTrigger
        trigger="#dj"
        start="top top"
        end="bottom top"
        scrub={0.5}
        // markers
        pin="#dj"
      ></ScrollTrigger>
      <ScrollTrigger
        trigger="#cake"
        start="top top"
        end="bottom top"
        scrub={0.5}
        // markers
        pin="#cake"
        onToggle={(s) => {
          if (!cakeReady) {
            setCakeReady(s.direction === -1 ? s.isActive : true)
          }
        }}
      ></ScrollTrigger>
      {/* </React.Fragment> */}
    </Page>
  )
}

export default FirstBirthday
