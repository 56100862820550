/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Tween, ScrollTrigger, Timeline, PlayState } from 'react-gsap'
import SvgNetherlands from '../svgs/Netherlands'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

const BikeAnimation = ({ trigger }) => {
  const [nlState, setNlState] = useState(PlayState.stop)
  return (
    <>
      <TweenLandscape>
        <ScrollTrigger
          trigger={trigger}
          start="top top"
          end="bottom top"
          scrub={true}
          // markers
          onToggle={(s) => {
            if (s.isActive) {
              setNlState(
                s.direction === 1 ? PlayState.play : PlayState.restartReverse
              )
            } else {
              // setNlState(PlayState.pause)
            }
          }}
        />
        <Timeline target={<SvgNetherlands width="100%" />} playState={nlState}>
          <Tween
            target="SvgNetherlandsFrontWheel"
            to={{
              rotation: 360,
              ease: 'linear.easeNone',
              transformOrigin: '50% 50%',
            }}
            position={0}
          />
          <Tween
            target="SvgNetherlandsBackWheel"
            to={{
              rotation: 360,
              ease: 'linear.easeNone',
              transformOrigin: '50% 50%',
            }}
            position={0}
          />
          <Tween
            target="SvgNetherlandsPedals"
            to={{
              rotation: 360,
              ease: 'linear.easeNone',
              transformOrigin: '50% 50%',
            }}
            position={0}
          />
          <Tween
            target="SvgNetherlandsFiets"
            to={{
              xPercent: '200',
            }}
            position={0}
          />
        </Timeline>
      </TweenLandscape>
      <TweenPortrait>
        <ScrollTrigger
          trigger={trigger}
          start="top top"
          end="bottom top"
          scrub={true}
          // markers
          onToggle={(s) => {
            if (s.isActive) {
              setNlState(
                s.direction === 1 ? PlayState.play : PlayState.restartReverse
              )
            } else {
              // setNlState(PlayState.pause)
            }
          }}
        />
        <Timeline
          target={<SvgNetherlands width="100%" viewBox="0 0 175 129.31" />}
          playState={nlState}
        >
          <Tween
            target="SvgNetherlandsFrontWheel"
            to={{
              rotation: 360,
              ease: 'linear.easeNone',
              transformOrigin: '50% 50%',
            }}
            position={0}
          />
          <Tween
            target="SvgNetherlandsBackWheel"
            to={{
              rotation: 360,
              ease: 'linear.easeNone',
              transformOrigin: '50% 50%',
            }}
            position={0}
          />
          <Tween
            target="SvgNetherlandsPedals"
            to={{
              rotation: 360,
              ease: 'linear.easeNone',
              transformOrigin: '50% 50%',
            }}
            position={0}
          />
          <Tween
            target="SvgNetherlandsFiets"
            to={{
              xPercent: '150',
            }}
            position={0}
          />
        </Timeline>
      </TweenPortrait>
    </>
  )
}

BikeAnimation.propTypes = {
  trigger: PropTypes.string,
}

const TweenPortrait = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  @media (orientation: portrait) {
    display: block;
  }
`

const TweenLandscape = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  @media (orientation: landscape) {
    display: block;
  }
`

export default BikeAnimation
