import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import HelpIcon from '@material-ui/icons/Help'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

const useStyles = makeStyles({
  root: {
    width: '80%',
    bottom: '1%',
    margin: '0 10%',
    position: 'fixed',
  },
})

const PlayControls = ({ score, level, onHelp, onSkip }) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const { t } = useTranslation()

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label={t('Level') + level}
        icon={<EmojiFlagsIcon />}
      />
      <BottomNavigationAction
        label={t('Score') + score}
        icon={<EmojiEventsIcon />}
      />
      <BottomNavigationAction
        label={t('Help')}
        icon={<HelpIcon onClick={() => onHelp()} />}
      />
      <BottomNavigationAction
        label={t('Skip')}
        icon={<SkipNextIcon onClick={() => onSkip()} />}
      />
    </BottomNavigation>
  )
}

PlayControls.propTypes = {
  score: PropTypes.number,
  level: PropTypes.number,
  onHelp: PropTypes.func,
  onSkip: PropTypes.func,
}

export default PlayControls
