import { useState, useEffect } from 'react'
import useWindowDimensions from './useWindowDimensions'

export default function useDeviceOrientation() {
  const { width, height } = useWindowDimensions()
  const [orientation, setOrientation] = useState()

  useEffect(() => {
    setOrientation(width > height ? 'landscape' : 'portrait')
  }, [width, height])
  return orientation
}
