import React, { createRef, useEffect, useState } from 'react'
import IntroDialog from './IntroDialog'
import { PropTypes } from 'prop-types'
import Loading from '../common/Loading'

function SvgLoader({ map, msg, showHelp, onSuccess }) {
  const svgRef = createRef()

  let eggElement = null
  const [eggClicked, setEggClicked] = useState(false)
  const [isLoaded, setLoaded] = useState(false)
  const [helpElement, setHelpElement] = useState(null)
  const [open, setOpen] = useState(false)

  const eggClickHandler = () => {
    setEggClicked(true)
    if (eggElement) {
      eggElement.style.setProperty('transition', 'fill-opacity 500ms ease-in')
      eggElement.style.setProperty(
        '-moz-transition',
        'fill-opacity 500ms ease-in'
      )
      eggElement.style.setProperty(
        '-webkit-transition',
        'fill-opacity 500ms ease-in'
      )
      eggElement.style.setProperty('fill-opacity', '0.8')
    }
    setTimeout(() => onSuccess(), 1000)
  }

  useEffect(() => {
    if (map) {
      setLoaded(false)
      setEggClicked(false)
      setHelpElement(false)
    }
  }, [map])

  const readyToGo = () => {
    setEggClicked(false)
    eggElement = svgRef.current.contentDocument.querySelector('svg g #egg')
    eggElement.addEventListener('click', eggClickHandler, false)
    eggElement.style.setProperty('fill-opacity', '0')

    const hEl = svgRef.current.contentDocument.querySelector('svg g #help')
    hEl.style.setProperty('fill-opacity', '0')
    setHelpElement(hEl)

    setLoaded(true)
    setOpen(true)
  }

  useEffect(() => {
    if (showHelp === true && helpElement !== null && eggClicked === false) {
      helpElement.style.setProperty('transition', 'fill-opacity 500ms ease-in')
      helpElement.style.setProperty(
        '-moz-transition',
        'fill-opacity 500ms ease-in'
      )
      helpElement.style.setProperty(
        '-webkit-transition',
        'fill-opacity 500ms ease-in'
      )
      helpElement.style.setProperty('fill-opacity', '0.2')
    }
  }, [showHelp, helpElement, eggClicked])

  return (
    <>
      <div
        style={{
          opacity: isLoaded ? 1 : 0,
        }}
      >
        <object
          data={map}
          onLoad={() => readyToGo()}
          ref={svgRef}
          style={{ width: '100%' }}
        >
          Level
        </object>
      </div>
      <IntroDialog open={open} setOpen={setOpen} text={msg}></IntroDialog>
      {!isLoaded && <Loading text="Loading" />}
    </>
  )
}

SvgLoader.propTypes = {
  map: PropTypes.node,
  msg: PropTypes.string,
  showHelp: PropTypes.bool,
  onSuccess: PropTypes.func,
}

export default SvgLoader
