import React, { useState, useEffect, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { AnimatedSprite, useTick } from '@inlet/react-pixi'
import usePixiCanvasSize from '../../hooks/usePixiCanvasSize'
import * as PIXI from 'pixi.js'

const playerSheet = {}
const w = 100
const h = 100
const speed = 4

const ssheet = PIXI.BaseTexture.from('/imgs/babyTiles.png')

playerSheet.idle = [
  new PIXI.Texture(ssheet, new PIXI.Rectangle(0 * w, h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(1 * w, h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(2 * w, h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(3 * w, h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(4 * w, h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(5 * w, h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(6 * w, h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(7 * w, h, w, h)),
]

playerSheet.crawling = [
  new PIXI.Texture(ssheet, new PIXI.Rectangle(0 * w, 0, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(1 * w, 0, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(2 * w, 0, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(3 * w, 0, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(4 * w, 0, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(5 * w, 0, w, h)),
]
playerSheet.lying = [
  new PIXI.Texture(ssheet, new PIXI.Rectangle(0 * w, 4 * h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(1 * w, 4 * h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(2 * w, 4 * h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(3 * w, 4 * h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(4 * w, 4 * h, w, h)),
  new PIXI.Texture(ssheet, new PIXI.Rectangle(5 * w, 4 * h, w, h)),
]

const Baby = ({ initX, initY, onClick, action, onLocked, hit }) => {
  const [textures, setTextures] = useState(playerSheet.idle)
  const [deltaX, setDeltaX] = useState(0)
  const [clicked, setClicked] = useState(false)
  const [play, setPlay] = useState(true)
  const [loop, setLoop] = useState(true)
  const [mirror, setMirror] = useState(1)
  const [centered, setCentered] = useState(false)
  const [animSpeed, setAnimSpeed] = useState(0.2)
  const { width } = usePixiCanvasSize()
  const [position, setPosition] = useState(0)
  const [tint, setTint] = useState(0xfffff)
  const myself = useRef()
  const [counter, setCounter] = useState(0)

  useTick((delta) => {
    if (clicked) {
      setDeltaX((x) => x + 2)
    }
    if (action === 'crawlRight' && !centered) {
      const spriteWidth = myself?.current?.getBounds()?.width || 0
      const newPosition = deltaX + speed
      if (newPosition + initX >= (width - spriteWidth) / 2) {
        setDeltaX(width / 2 - spriteWidth / 2 - initX)
        setCentered(true)
        onLocked(true)
      } else {
        setDeltaX(newPosition)
      }
      // } else if (movingLeft && centered) {
      //   setDeltaX((x) => x - 2)
    } else if (action === 'crawlLeft' /* && !centered */ && deltaX > speed) {
      setCentered(false)
      setDeltaX((x) => x - speed)
    }
    if (centered) {
      if (action === 'crawlRight') {
        setPosition((x) => x + speed)
      } else if (action === 'crawlLeft') {
        setPosition((x) => x - speed)
      }
      if (position === 0) {
        onLocked(false)
        setCentered(false)
      }
    }
    if (hit) {
      setCounter((c) => c + 1)
      if (counter % 10 === 0) {
        if (tint === 0xffffff) {
          setTint(0xbf0000)
        } else {
          setTint(0xffffff)
        }
      }
    } else if (tint !== 0xffffff) {
      setCounter(0)
      setTint(0xffffff)
    }
  })

  const setIdle = () => {
    setPlay(false)
    setTextures(playerSheet.idle)
    setLoop(true)
    setAnimSpeed(0.2)
    // setPlay(true)
  }

  const setLying = () => {
    setPlay(false)
    setTextures(playerSheet.lying)
    setLoop(true)
    setAnimSpeed(0.1)
    // setPlay(true)
  }

  const setCrawling = (dir) => {
    setPlay(false)
    setTextures(playerSheet.crawling)
    setMirror(dir)
    setAnimSpeed(0.2)
    // setLoop(false)
  }

  useEffect(() => {
    switch (action) {
      case 'crawlLeft':
        setCrawling(-1)
        break
      case 'crawlRight':
        setCrawling(1)
        break
      case 'sit':
        setIdle()
        break
      case 'lying':
      default:
        setLying()
    }
  }, [action])

  const handleClick = () => {
    setClicked(true)
    setPlay(false)
    setTextures(playerSheet.crawling)
    // setPlay(true)
    onClick()
  }

  useEffect(() => {
    setPlay(true)
  }, [textures])

  return (
    <AnimatedSprite
      animationSpeed={animSpeed}
      ref={myself}
      x={initX + deltaX}
      y={initY}
      // scale={2}
      anchor={{ x: 0.5, y: 1 }}
      interactive={onClick !== undefined}
      textures={textures}
      isPlaying={play}
      loop={loop}
      scale={{ x: mirror, y: 1 }}
      pointerdown={() => handleClick()}
      tint={tint}
    />
  )
}

Baby.propTypes = {
  initX: PropTypes.number,
  initY: PropTypes.number,
  onClick: PropTypes.func,
  action: PropTypes.string,
  onLocked: PropTypes.func,
  hit: PropTypes.bool,
}

export default Baby
