import React, { useState } from 'react'
import { Tween, ScrollTrigger, Timeline, PlayState } from 'react-gsap'
import SvgCake from '../svgs/Cake'
import { PropTypes } from 'prop-types'

const CakeAnimation = ({ trigger, active }) => {
  const [cakeState, setCakeState] = useState(PlayState.stop)
  return (
    <>
      {active ? (
        <>
          <ScrollTrigger
            trigger={trigger}
            start="top top"
            end="bottom top"
            scrub={true}
            // markers
            onToggle={(s) => {
              if (s.isActive) {
                setCakeState(
                  s.direction === 1 ? PlayState.play : PlayState.restartReverse
                )
              } else {
                setCakeState(PlayState.stopEnd)
              }
            }}
          />

          <Timeline target={<SvgCake width="100%" />} playState={cakeState}>
            <Tween
              target="SvgCakePath"
              to={{
                opacity: 0,
              }}
              from={{
                opacity: 0,
              }}
              position={0}
            />
            <Tween
              target="SvgCakeTarget"
              to={{
                attr: { href: '/imgs/personal/gimmeCake.png' },
              }}
              from={{
                attr: { href: '/imgs/personal/gimmeCake.png' },
              }}
              position={0}
            />
            <Tween
              target="SvgCakePiece"
              position={0}
              to={{
                ease: 'power1.inOut',
                motionPath: {
                  path: '#SvgCakePath',
                  align: '#SvgCakePath',
                  alignOrigin: [0.5, 0.5],
                },
              }}
            />
            <Tween
              target="SvgCakeTarget"
              stagger="SvgCakePiece"
              to={{
                attr: { href: '/imgs/personal/gotCake.png' },
              }}
              position={1}
            />
            <Tween
              target="SvgCakePiece"
              position={1}
              to={{
                opacity: 0,
              }}
              duration={0}
            />
          </Timeline>
        </>
      ) : (
        <Timeline target={<SvgCake width="100%" />}>
          <Tween
            target="SvgCakePath"
            to={{
              opacity: 0,
            }}
            from={{
              opacity: 0,
            }}
            position={0}
          />
          <Tween
            target="SvgCakeTarget"
            from={{
              attr: { href: '/imgs/personal/gimmeCake.png' },
            }}
            to={{
              attr: { href: '/imgs/personal/gimmeCake.png' },
            }}
            position={0}
          />
        </Timeline>
      )}
    </>
  )
}

CakeAnimation.propTypes = {
  trigger: PropTypes.string,
  active: PropTypes.bool,
}

export default CakeAnimation
