import React, { useState, useContext } from 'react'
import { AuthContext } from '../contexts/authContext'
import SvgLoader from '../components/easterhunt/SvgLoader'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { Redirect } from 'react-router-dom'
import Playcontrols from '../components/easterhunt/PlayControls'
import FinishDashboard from '../components/easterhunt/FinishDashboard'

function EasterHunt() {
  const { authState } = useContext(AuthContext)

  const levels = [
    { img: '/imgs/personal/level1.svg', msg: 'level1' },
    { img: '/imgs/personal/level2.svg', msg: 'level2' },
    { img: '/imgs/personal/level3.svg', msg: 'level3' },
    { img: '/imgs/personal/level5.svg', msg: 'level5' },
    { img: '/imgs/personal/level6.svg', msg: 'level6' },
    { img: '/imgs/personal/level7.svg', msg: 'level7' },
    { img: '/imgs/personal/level8.svg', msg: 'level8' },
    { img: '/imgs/personal/level9.svg', msg: 'level9' },
  ]
  const [needHelp, askHelp] = useState(false)
  const [status, setStatus] = useState({
    points: 0,
    level: 0,
  })
  const [isFinished, setFinished] = useState(false)
  const { height, width } = useWindowDimensions()

  const addPoint = (val) => {
    const points = status.points + val
    const level = status.level + 1
    if (level >= levels.length) {
      setFinished(true)
      setStatus({ points, level })
    } else {
      setStatus({ points, level })
    }
    askHelp(false)
  }

  const successHandler = () => {
    addPoint(1)
  }

  const restart = () => {
    setFinished(false)
    setStatus({
      points: 0,
      level: 0,
    })
  }

  return (
    <>
      {!authState.isAuth ? (
        <Redirect to="/login" />
      ) : (
        <div className="App" style={{ flexGrow: 1 }}>
          {isFinished ? (
            <FinishDashboard
              score={status.points}
              onRestart={() => restart()}
            ></FinishDashboard>
          ) : (
            <div>
              <SvgLoader
                width={width}
                height={height}
                map={levels[status.level].img}
                msg={levels[status.level].msg}
                showHelp={needHelp}
                onSuccess={successHandler}
              ></SvgLoader>
              <Playcontrols
                score={status.points}
                level={status.level}
                onHelp={() => askHelp(true)}
                onSkip={() => addPoint(0)}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default EasterHunt
