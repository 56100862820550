import 'cross-fetch/polyfill'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'

var cognitoUser, sessionUserAttributes
const cookieStorage = new AmazonCognitoIdentity.CookieStorage({
  domain: process.env.REACT_APP_DOMAIN,
  secure: process.env.REACT_APP_COGNITO_SECURECOOKIES === 'true',
  expires: parseInt(process.env.REACT_APP_COGNITO_EXPCOOKIES),
})
const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
  Storage: cookieStorage,
}
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

export const LogOut = () => {
  return new Promise((resolve, reject) => {
    cognitoUser.globalSignOut({
      onSuccess: function (ok) {
        resolve(ok)
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  })
}

export const GetCurrentSession = () => {
  return new Promise((resolve, reject) => {
    cognitoUser = userPool.getCurrentUser()
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          alert(err.message || JSON.stringify(err))
          reject(err)
          return
        }
        // var accessToken = session.getAccessToken().getJwtToken();
        var idToken = session.getIdToken().getJwtToken()
        // console.log('session validity: ' + session.isValid());

        // NOTE: getSession must be called to authenticate user before calling getUserAttributes
        cognitoUser.getUserAttributes(function (err, attr) {
          if (err) {
            reject(err)
            // Handle error
          } else {
            resolve({
              token: idToken,
              usrAttr: {
                locale: attr.find((i) => i.Name === 'locale').Value,
                profile: JSON.parse(
                  attr.find((i) => i.Name === 'profile').Value
                ),
              },
            })
          }
        })
      })
    } else {
      reject(new Error('No user currently logged in'))
    }
  })
}

export const DoUserPswChallenge = (
  session,
  username,
  newPassword,
  newPassword2
) => {
  return new Promise((resolve, reject) => {
    if (newPassword !== newPassword2) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        message: 'Passwords provided do not match',
      })
    }
    var userData = {
      Username: username,
      Pool: userPool,
    }
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    cognitoUser.Session = session
    cognitoUser.completeNewPasswordChallenge(newPassword, null, {
      onSuccess: (result) => {
        resolve(result)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export const LogUserIn = (Username, Password) => {
  return new Promise((resolve, reject) => {
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      {
        Username,
        Password,
      }
    )
    var userData = {
      Username: Username,
      Pool: userPool,
      Storage: cookieStorage,
    }
    cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        // var accessToken = result.getAccessToken().getJwtToken();
        var idToken = result.getIdToken().getJwtToken()

        cognitoUser.getUserAttributes(function (err, res) {
          if (err) {
            alert(err.message || JSON.stringify(err))
            return
          }

          resolve({
            token: idToken,
            usrAttr: {
              locale: res.find((i) => i.Name === 'locale').Value,
              profile: JSON.parse(res.find((i) => i.Name === 'profile').Value),
            },
          })
        })
      },

      onFailure: function (err) {
        alert(err.message || JSON.stringify(err))
        reject(err)
      },
      newPasswordRequired: function (usrAttr, reqAttr) {
        delete usrAttr.email_verified
        // store userAttributes on global variable
        sessionUserAttributes = usrAttr

        // store userAttributes on global variable
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ reason: 'newPassword', session: cognitoUser.Session })
      },
    })
  })
}

export const handleNewPassword = (newPassword) => {
  cognitoUser.completeNewPasswordChallenge(newPassword, sessionUserAttributes)
}
