import styled from 'styled-components'
import Devices from '../../shared/Devices'

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  color: white;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  background-color: ${(props) => props.bkgColor || 'palevioletred'};
  flex-direction: ${(props) => props.direction || 'column'};
`

const PageHeader = styled.h1`
  font-family: 'Bungee', cursive;
  text-shadow: 3px 3px #707070;
  color: #f8f8f8;
  font-size: 1.5em;
  margin: 0;
  @media ${Devices.laptop} {
    font-size: 4em;
  }
`

const GameHeader = styled.h1`
  font-family: 'Press Start 2P', cursive;
  text-shadow: 5px 5px #fffb00;
  color: #73ad;
  font-size: 1em;
  line-height: 1.5em;
  @media ${Devices.laptop} {
    font-size: 2em;
  }
`
const TextBox = styled.p`
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  border: 3px solid #73ad00;
  border-radius: 25px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  min-width: 200px;
  height: auto;
  @media (orientation: landscape) {
    max-width: ${(props) => props.maxWidthLandscape || '40%'};
    max-height: 100%;
    margin-top: 0;
    margin-bottom: auto;
  }
  @media (orientation: portrait) {
    max-height: 40%;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: 0;
  }
`
const BigTextBox = styled(TextBox)`
  margin: 5%;
  font-size: 1em;
  max-width: 100%;
  @media ${Devices.laptop} {
    font-size: 2em;
  }
`

const Container = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media ${Devices.laptop} {
    font-size: 2em;
  }
  @media (orientation: landscape) {
    flex-direction: row;
  }
  @media (orientation: portrait) {
    flex-direction: column;
  }
`
const JoinButton = styled.button`
  font-family: 'Press Start 2P', cursive;
  font-size: 1em;
  color: #800080;
  background-color: #f8b600;
  border-radius: 0.5em;
  padding: 0.5em;
  cursor: pointer;
  :hover {
    background-color: yellow;
  }

  @media ${Devices.laptop} {
    font-size: 2em;
  }
`

export {
  Panel,
  PageHeader,
  GameHeader,
  TextBox,
  BigTextBox,
  Container,
  JoinButton,
}
