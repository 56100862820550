import React, { createContext, useState } from 'react'
import { PropTypes } from 'prop-types'

export const initialState = { displayTopBar: true }
export const AppContext = createContext(initialState)
function AppCtxProvider(props) {
  const [appState, setAppState] = useState(initialState)
  const hideTopBar = () => {
    setAppState((s) => ({ ...s, displayTopBar: false }))
  }
  const showTopBar = () => {
    setAppState((s) => ({ ...s, displayTopBar: true }))
  }
  return (
    <AppContext.Provider
      value={{ displayTopBar: appState.displayTopBar, hideTopBar, showTopBar }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

AppCtxProvider.propTypes = {
  children: PropTypes.node,
}

export { AppCtxProvider }
