import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      Loading: 'Loading...',
      Help: 'Help',
      Skip: 'Skip',
      OSP: 'One Sock Pirates',
      Username: 'Username',
      Password: 'Password',
      SignIn: 'Sign In',
      Logout: 'Logout',
      YouFound: 'You found ',
      Eggs: ' easter eggs!',
      HaveYou: 'have you',
      HaveYou_pural: 'have you',
      FinishedMsg:
        'Arturo is delighted to {{haveYou}} as a {{relationship}}! Thanks a lot for your help {{name}}!!!',
      attention: 'Help Arturo',
      level1:
        'Arturo is playing on his gym, can you help him find the hidden easter egg?',
      level2:
        'Arturo felt asleep without finding the next egg, can you find it?',
      level3:
        'Ready to go for a walk! Can you see the easter egg that was left behind?',
      level4:
        'And back again asleep, Arturo is very tired! The easter bunny left something behind, right?',
      level5: 'Happy cake time! Did Arturo get an easter egg as a present?',
      level6:
        'Arturo is so surprised that you are finding all the eggs. Can you see the one here?',
      level7:
        'Arturo looks cool in his hoodie, and would be even cooler with an easter egg!',
      level8:
        "It's so hot, so Arturo is in his shorts, do you see an easter egg here?",
      level9:
        'What?! It was hot and now is cold, did the easter bunny leave an egg in the snow',
      Level: 'Level: ',
      Score: 'Score: ',
      CurrentPsw: 'Current Password',
      NewPsw: 'New Password',
      RepeatPsw: 'Repeat New Password',
      Update: 'Update',
      UpdatePsw: 'You must change your password before logging in',
      EasterHunt: 'Easter Hunt',
      FirstBirthday: '1st Birthday',
      AboutUs: 'About Us',
      Administration: 'Administration',
      NoDataEvent: 'There is no date for this event',
      EventTooLate: 'You are too late for this event',
      CallingEvent: 'Joining the event',
      AlmostEvent: 'It is almost time. Get ready!',
      MinutesToEvent: 'What excitement! Such thrill!',
      HoursToEvent: 'Get dressed with your sunday clothes...',
      DaysToEvent: 'Get comfy, there are still some days left',
      Day: 'day',
      Day_plural: 'days',
      Hour: 'hour',
      Hour_plural: 'hours',
      Minute: 'minute',
      Minute_plural: 'minutes',
      Second: 'second',
      Second_plural: 'seconds',
      TimeLeft: 'There are still left',
      Birthday: {
        Header: '{{name}}, we have something to celebrate!',
        Pane1: {
          Title: 'A long time ago...',
          Msg: 'Who could have imagined that a trip to the Netherlands could give so much?',
        },
        Pane2: {
          Title: 'Fast forward 5 years',
          Msg: `Laura and Jorge's dreams came to life when cute Arturo introduced himself a 12th of September in Veldhoven. 
          Little by little this little man has grown as much as his appetite.
          Maybe it is difficult to imagine, but this little boy has turn in quite the mischievous kid.`,
        },
        Pane3: {
          Title: '12 months later',
          Msg: `It is now the moment to celebrate Arturo's first birthday.
          There's not much cool people left nowadays, but Arturo is the mostest.
          In only one year he has developed his most hunky-dory side, even surpasing
          his parents' coolness`,
        },
        Pane4: {
          Title: 'Party!',
          Msg: `And birthday means party, music, friends and family! Precisly what Arturo likes
          the most. That and that everyone around him pays him attention, but how not to? Have you seen his cheeks?! `,
        },
        Pane5: {
          Title: 'And, of course, there will be cake!',
          Msg: ``,
        },
        JoinPane: {
          Banner: 'Online Party',
          Msg: `Despite the distance, we would like to share this special day with you!
          We have setup an online call, so that even from far away you can be close`,
          Btn: 'Join',
        },
      },
      Game1: {
        Points: 'Points',
        YouLoose: 'You have lost, do you want to try again?',
        YouWin: 'You won! Congratulations for your {{points}} points',
        PlayAgain: 'Play Again',
        GoBack: 'GoBack',
        Level1Msg: "Help Arturo avoid all the things he doesn't like!",
        SoundOn: 'Turn your volume up',
        RotateDevice: 'Rotate your device',
        ClickStart: 'Click/Touch to start',
        LoadingAssets: 'Loading assets...',
        PlayWithBaby:
          'In the meantime, you can play with Arturo...\nClick on the baby!',
      },
    },
  },
  es: {
    translation: {
      Loading: 'Cargando...',
      Help: 'Ayuda',
      Skip: 'Saltar',
      OSP: 'One Sock Pirates',
      Username: 'Nombre de usuario',
      Password: 'Contraseña',
      SignIn: 'Iniciar Sesion',
      Logout: 'Salir',
      YouFound: 'Has encontrado ',
      Eggs: ' huevos de pascua!',
      HaveYou: 'tenerte',
      HaveYou_pural: 'teneros',
      FinishedMsg:
        'Arturo esta encantado de {{haveYou}} como {{relationship}}! Muchas gracias por la ayuda {{name}}!!!',
      attention: 'Ayuda a Arturo',
      level1:
        'Arturo se entrena en su gimnasio, puedes encontrar el huevo de pascua escondido?',
      level2:
        'Arturo se quedo dormido buscando el siguiente huevo, puedes verlo?',
      level3: 'Listo para un paseo! Puedes ver el huevo que se olvido?',
      level4:
        'Y vuelta a dormir, Arturo esta muy cansado! El conejo de pascua se dejo algo, verdad?',
      level5: 'Tarta para Arturo! Habra huevo de pascua como regalo?',
      level6:
        'Arturo esta realmente sorprendido de que bien encuentras los huevos. Ves el siguiente?',
      level7:
        'Arturo esta muy molon con su sudadero, molaria aun mas si encontrases el siguiente huevo de pascua!',
      level8:
        'Que calor! Arturo esta sin pantalones! Hay algun huevo a la vista?',
      level9:
        'Que?! Hacia calor y ahora frio. Dejo el conejito de pascua algun huevo en la nieve?',
      Level: 'Nivel: ',
      Score: 'Puntos: ',
      CurrentPsw: 'Contraseña actual',
      NewPsw: 'Nueva contraseña',
      RepeatPsw: 'Repita la nueva contraseña',
      Update: 'Actualizar',
      UpdatePsw: 'Debe actualizar su contraseña antes de poder iniciar sesión',
      EasterHunt: 'Huevos de Pascua',
      FirstBirthday: '1er Cumpleaños',
      AboutUs: 'Acerca de nosotros',
      Administration: 'Administración',
      NoDataEvent: 'No hay fecha para este evento',
      EventTooLate: 'Llegaste tarde para este evento',
      CallingEvent: 'Conectando al evento',
      AlmostEvent: 'Ya casi es el momento. Preparate!',
      MinutesToEvent: 'Que nervios! Que emocion!',
      HoursToEvent: 'Vistete con las mejores galas...',
      DaysToEvent: 'Ponte comodo, aun faltan algunos dias',
      Day: 'dia',
      Day_plural: 'dias',
      Hour: 'hora',
      Hour_plural: 'horas',
      Minute: 'minuto',
      Minute_plural: 'minutos',
      Second: 'segundo',
      Second_plural: 'segundos',
      TimeLeft: 'Aun quedan:',
      Birthday: {
        Header: '{{name}}, tenemos algo que celebrar!',
        Pane1: {
          Title: 'Hace mucho tiempo...',
          Msg: 'Quien podria haber imaginado que un viaje a los Paises Bajos daria para tanto',
        },
        Pane2: {
          Title: '5 años más tarde',
          Msg: `Los sueños de Laura y mios se hicieron realidad cuand Arturo se dio a conocer un 12 de septiembre en Veldhoven. 
          Poco a poco este hombrecillo ha crecido igual que su apetito. 
          Es posbile que sea dificil de imaginar, pero se ha convertido en un niño bastante travieso`,
        },
        Pane3: {
          Title: '12 meses después',
          Msg: `Ha llegado la hora celebrar el primer cumpleaños de Arturo.
          Queda poca gente "molona" o "guay", pero sin duda Arturo es el maximo exponente.
          En solo un año ha desarrollar su faceta mas cool, sobrepasando incluso
          la de sus progenitores`,
        },
        Pane4: {
          Title: 'Fiesta!',
          Msg: `Y cumpleaños significa fiesta, música, amigos y familia! Precisamente lo que más 
          le gusta a Arturo. Eso y que todo el mundo le preste atención, pero como no hacerlo? Habeis visto sus mejillas?!`,
        },
        Pane5: {
          Title: 'Y, por supuesto, habrá pastel!',
          Msg: ``,
        },
        JoinPane: {
          Banner: 'Fiesta en linea',
          Msg: `A pesar de la distancia, nos gustaria compartir este dia tan especial con vosotros! 
          Hemos preparado una conferencia en linea, para que desde lejos podais estar cerca`,
          Btn: 'Unirse',
        },
      },
      Game1: {
        Points: 'Puntos',
        YouLoose: 'Has perdido! Quieres intentarlo de nuevo?',
        YouWin: 'Ganaste! Felicitaciones por tus {{points}} puntos',
        PlayAgain: 'Jugar de nuevo',
        GoBack: 'Volver atrás',
        Level1Msg: 'Ayuda a Arturo a esquivar las cosas que no le gustan!',
        SoundOn: 'Sube el volumen',
        RotateDevice: 'Gira tu dispositivo',
        ClickStart: 'Click/Toca para empezar',
        LoadingAssets: 'Cargando librerias...',
        PlayWithBaby:
          'Mientras, puedes jugar con Arturo...\nDale click al bebé!',
      },
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
