import React, { createContext, useState } from 'react'
import { PropTypes } from 'prop-types'

export const initialState = { isAuth: false, usrAttr: null }
export const AuthContext = createContext(initialState)
function AuthCtxProvider(props) {
  const [authState, setAuthState] = useState(initialState)
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {props.children}
    </AuthContext.Provider>
  )
}

AuthCtxProvider.propTypes = {
  children: PropTypes.node,
}

export { AuthCtxProvider }
