import AWS from 'aws-sdk'
// import CognitoProvider from "../services/cognito"
import { AuthContext } from '../contexts/authContext'
import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  forwardRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { PropTypes } from 'prop-types'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function getModalStyle() {
  const top = 50
  const left = 47

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    padding: '3%',
    margin: '3%',
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const emptyUser = {
  Username: 'new user',
  Attributes: [
    { Name: 'username', Value: '' },
    { Name: 'email_verified', Value: true },
    {
      Name: 'profile',
      Value: '{"name":"XXX", "relationship":"xxx", "count":1}',
    },
    { Name: 'locale', Value: 'es' },
    { Name: 'email', Value: 'xxxx' },
  ],
}

const Users = () => {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const { authState } = useContext(AuthContext)
  const [users, setUsers] = useState([])
  const [ready, setReady] = useState(false)
  const [cisp, setCisp] = useState(null)
  const [open, setOpen] = useState(false)
  const [updated, setUpdated] = useState(true)
  const [updateUser, setUpdateUser] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  const handleOpenDialog = (user) => {
    setUpdateUser(user)
    setOpenDialog(true)
  }

  const handleCloseDialog = (isConfirmed) => {
    setOpenDialog(false)
    if (isConfirmed) deleteUser(updateUser.Username)
  }

  const handleOpen = (user) => {
    if (user === undefined) {
      user = {}
    }
    setUpdateUser(user)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // Initialize Cognito Identity Pool credentials for admin on creation
  useEffect(() => {
    AWS.config.region = 'eu-west-1'

    const Logins = {}
    Logins[
      `cognito-idp.eu-west-1.amazonaws.com/${process.env.REACT_APP_COGNITO_USERPOOLID}`
    ] = authState.token

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOLID,
      Logins,
    })
    // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
    AWS.config.credentials.refresh((error) => {
      if (error) {
        // console.error(error);
        console.log('This user has no access to AWS')
      } else {
        setCisp(new AWS.CognitoIdentityServiceProvider())
        setReady(true)
      }
    })
  }, [authState.token])

  const handleFormSubmission = (event) => {
    event.preventDefault()
    const attributes = []
    const notMutableAttr = ['', 'sub', 'username']
    let username
    Object.keys(event.target.elements).forEach((t) => {
      if (!notMutableAttr.includes(event.target.elements[t].id)) {
        attributes.push({
          Name: event.target.elements[t].id,
          Value: event.target.elements[t].value,
        })
      } else if (event.target.elements[t].id === 'username') {
        username = event.target.elements[t].value
      }
    })
    if (username) {
      createUser({
        Username: username,
        Attributes: attributes,
      })
    } else {
      updateUserAttributes({
        Username: updateUser.Username,
        Attributes: attributes,
      })
    }
  }

  const updateUserAttributes = (data) => {
    var params = {
      UserAttributes: data.Attributes,
      UserPoolId: process.env.REACT_APP_COGNITO_USERPOOLID /* required */,
      Username: data.Username,
    }
    cisp.adminUpdateUserAttributes(params, function (err, data) {
      if (err) console.log(err, err.stack)
      // an error occurred
      else {
        setUpdated(true)
        setUpdateUser(undefined)
      }
    })
    handleClose()
  }

  const createUser = (data) => {
    var params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USERPOOLID /* required */,
      Username: data.Username /* required */,
      TemporaryPassword: authState.usrAttr.profile.tmpPsw,
      UserAttributes: data.Attributes,
    }
    cisp.adminCreateUser(params, function (err, data) {
      if (err) console.log(err, err.stack)
      // an error occurred
      else {
        setUpdated(true)
        setUpdateUser(undefined)
      }
    })
    handleClose()
  }

  // const forceNewPassword = (data) => {
  //   var params = {
  //     Password: authState.usrAttr.profile.tmpPsw,
  //     UserPoolId: process.env.REACT_APP_COGNITO_USERPOOLID /* required */,
  //     Username: data.Username /* required */,
  //     Permanent: false,
  //   }
  //   cognitoidentityserviceprovider.adminSetUserPassword(
  //     params,
  //     function (err, data) {
  //       if (err) console.log(err, err.stack)
  //       // an error occurred
  //       else console.log(data) // successful response
  //     }
  //   )
  // }

  const deleteUser = (username) => {
    var params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USERPOOLID /* required */,
      Username: username,
    }
    cisp.adminDeleteUser(params, function (err, data) {
      if (err) console.log(err, err.stack)
      // an error occurred
      else setUpdated(true)
    })
  }

  // Once credentials are initialized call get Users
  useEffect(() => {
    if (ready === true && updated === true) {
      var params = {
        UserPoolId: process.env.REACT_APP_COGNITO_USERPOOLID /* required */,
      }
      cisp
        .listUsers(params)
        .promise()
        .then(
          (data) => {
            setUsers(data.Users)
          },
          (err) => {
            console.log(err, err.stack) // an error occurred
          }
        )
      setUpdated(false)
    }
  }, [cisp, ready, updated])

  const Row = (props) => {
    const { user } = props
    const [openRow, setOpenRow] = useState(false)
    return (
      <Fragment key={user.Username}>
        <TableRow className={classes.root}>
          <TableCell width="50px">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenRow(!openRow)}
            >
              {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="left" component="th" scope="row">
            {user.Username}
          </TableCell>
          <TableCell align="right" width="50px">
            <IconButton
              aria-label="update row"
              size="small"
              onClick={() => handleOpen(user)}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell align="right" width="50px">
            <IconButton
              aria-label="delete row"
              size="small"
              onClick={() => handleOpenDialog(user)}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className={classes.root}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={openRow} timeout="auto" unmountOnExit>
              <Box margin={1}>
                {user.Attributes.map((a) => {
                  if (a.Name !== 'profile') {
                    return <p key={a.Name}>{`${a.Name}: ${a.Value}`}</p>
                  } else {
                    let profile = a.Value
                    try {
                      profile = JSON.parse(profile)
                      return (
                        <Fragment key={a.Name}>
                          <p>{`${a.Name}: `}</p>
                          {Object.keys(profile).map((k) => (
                            <p
                              style={{ paddingLeft: '20px' }}
                              key={k}
                            >{`${k}: ${profile[k]}`}</p>
                          ))}
                        </Fragment>
                      )
                    } catch {
                      return <p key={a.Name}>{`${a.Name}: ${a.Value}`}</p>
                    }
                  }
                })}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }

  Row.propTypes = {
    user: PropTypes.object,
  }

  return (
    <>
      {ready ? (
        <>
          <h1>User Administration</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen(emptyUser)}
          >
            Create New User
          </Button>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableBody>
                {users.map((u) => (
                  <Row key={u.Username} user={u} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.paper}>
              {open && (
                <form
                  onSubmit={handleFormSubmission}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <h2 id="simple-modal-title">
                    Update Attributes for {updateUser.Username}
                  </h2>
                  {updateUser.Attributes.map((a) => (
                    <TextField
                      key={a.Name}
                      id={a.Name}
                      label={a.Name}
                      color="secondary"
                      defaultValue={a.Value}
                      multiline
                      rows={a.Name === 'profile' ? 4 : 1}
                    />
                  ))}
                  <br />
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </form>
              )}
            </div>
          </Modal>
          {updateUser && (
            <Dialog
              open={openDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {'Are you sure you want to delete this user?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {`User ${updateUser.Username} will be removed forever and access will be lost.`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleCloseDialog(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={() => handleCloseDialog(true)} color="primary">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      ) : (
        <h1>No access</h1>
      )}
    </>
  )
}
export default Users
