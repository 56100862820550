import React, { useCallback, useState } from 'react'
import { Graphics, useTick, useApp } from '@inlet/react-pixi'
import { PropTypes } from 'prop-types'

let i = 0
const Transition = ({ isDone }) => {
  const [progress, setProgress] = useState(0)
  const [active, setActive] = useState(true)
  const app = useApp()

  useTick((delta) => {
    i += 0.008 * delta
    const newProgress = Math.round(i * 25) / 25
    if (active) {
      if (progress > 1) {
        setActive(false)
        isDone()
      } else if (newProgress !== progress) {
        setProgress(newProgress)
      }
    }
  })

  const draw = useCallback(
    (g) => {
      const width = app.renderer.view.clientWidth
      const height = app.renderer.view.clientHeight

      g.clear()

      g.beginFill(0x000000)
      g.moveTo(width / 2, height / 2)
      g.lineTo(width / 2, height)
      g.lineTo((width / 2) * Math.min(2, 1 + progress * 2), height)
      g.lineTo(width, height * Math.max(1 - (progress - 0.5), 0))
      g.lineTo(width / 2, height / 2)
      g.endFill()

      g.beginFill(0x000000)
      g.moveTo(width / 2, height / 2)
      g.lineTo(width, height / 2)
      g.lineTo(width, (height / 2) * Math.max(1 - progress * 2, 0))
      g.lineTo(width * Math.max(1 - (progress - 0.5), 0), 0)
      g.lineTo(width / 2, height / 2)
      g.endFill()

      g.beginFill(0x000000)
      g.moveTo(width / 2, height / 2)
      g.lineTo(width / 2, 0)
      g.lineTo((width / 2) * Math.max(1 - progress * 2, 0), 0)
      if (progress >= 0.5) g.lineTo(0, height * Math.max(progress - 0.5, 0))
      g.lineTo(width / 2, height / 2)
      g.endFill()

      g.beginFill(0x000000)
      g.moveTo(width / 2, height / 2)
      g.lineTo(0, height / 2)
      g.lineTo(0, height * Math.min(progress + 0.5, 1))
      if (progress >= 0.5) g.lineTo(width * Math.max(progress - 0.5, 0), height)
      g.lineTo(width / 2, height / 2)

      g.endFill()
    },
    [progress, app.renderer.view.clientWidth]
  )
  return <Graphics draw={draw} />
}

Transition.propTypes = {
  isDone: PropTypes.func,
}
export default Transition
