import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthCtxProvider } from './contexts/authContext'
import { AppCtxProvider } from './contexts/appContext'

ReactDOM.render(
  // <React.StrictMode>
  <AuthCtxProvider>
    <AppCtxProvider>
      <App />
    </AppCtxProvider>
  </AuthCtxProvider>,
  // </React.StrictMode>
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
