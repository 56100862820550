import * as React from 'react'
const SvgCake = React.forwardRef((props, ref) => {
  const SvgCakeRoot = React.useRef(null)
  const SvgCakePath = React.useRef(null)
  const SvgCakePiece = React.useRef(null)
  const SvgCakeTarget = React.useRef(null)
  React.useImperativeHandle(ref, () => ({
    SvgCakeRoot,
    SvgCakePath,
    SvgCakePiece,
    SvgCakeTarget,
  }))
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="id_svg8"
      viewBox="0 0 62.912 21.974"
      ref={SvgCakeRoot}
      {...props}
    >
      <g transform="translate(-33.97 -70.298)" id="SvgCakeLayer1">
        <path
          id="SvgCakePath"
          d="M49.51 76.648c13.312-9.429 11.192 6.417 16.067 6.494 6.482.023 5.545-8.43 15.566-5.01 7.969 3.386.23 6.306 8.734 7.358"
          fill="none"
          stroke="#000"
          strokeWidth={0.265}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity={1}
          ref={SvgCakePath}
        />
        <path
          d="M47.876 73.603c-.188.034-.406.363-.612.508a9.816 1.748 0 01.986.078.785.785 0 01-.088-.268c-.08-.261-.179-.338-.286-.318z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath3294"
          fill="#ffd5d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M46.623 73.665c-.185.018-.438.29-.67.376a9.816 1.748 0 01.976.05.584.584 0 01-.071-.12c-.045-.243-.128-.317-.235-.306z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath3296"
          fill="#ffd5d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M44.294 75.666l6.638-1.104v1.222z"
          id="SvgCakePath1174"
          fill="#fea"
          stroke="#000"
          strokeWidth={0}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeOpacity={1}
        />
        <path
          d="M41.294 73.519a.74.74 0 01-.589.568 9.816 1.748 0 011.56-.067c-.345-.093-.708-.358-.97-.501zm3.963.007c-.247-.02-.483.495-.797.474l-.004.003a9.816 1.748 0 011.25.03c-.073-.041-.14-.12-.195-.27-.088-.165-.171-.23-.254-.237zm-1.953.009c-.076-.012-.152.016-.232.108-.096.198-.216.31-.349.366a9.816 1.748 0 01.815-.01 9.816 1.748 0 01.363 0c-.222-.157-.408-.435-.597-.465z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath3292"
          fill="#ffd5d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M33.986 75.666h10.308l6.638-1.104a9.816 1.748 0 00-6.861-.549 9.816 1.748 0 00-.375 0 9.816 1.748 0 00-9.71 1.653z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath1176"
          fill="#a40"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={4}
          strokeDasharray="none"
        />
        <g
          id="SvgCakeG3242"
          transform="matrix(.42294 0 0 .47809 33.007 39.563)"
          strokeWidth={0}
          stroke="#000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeOpacity={1}
        >
          <path
            id="SvgCakePath3238"
            d="M32.222 73.516l-.26.26.553-.27.219.26.157-.24.59.214-.34-.569-.771.034z"
            fill="green"
          />
          <path
            id="SvgCakePath3240"
            d="M31.656 72.477s.175-.81.354-1.057c.174-.24.638-.62.638-.62s.483.371.661.614c.185.253.402.85.402.85s-.007 1.069-.402 1.276c-.19.1-.614-.189-.614-.189s-.378.202-.543.118c-.4-.203-.496-.992-.496-.992z"
            fill="#ff2a2a"
            fillOpacity={1}
          />
        </g>
        <path
          id="SvgCakePath1087"
          d="M44.294 75.666l6.638-1.104v1.222z"
          fill="#fea"
          stroke="#000"
          strokeWidth={0}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeOpacity={1}
        />
        <path
          id="SvgCakePath1137"
          style={{
            mixBlendMode: 'normal',
          }}
          d="M33.974 78.363a9.816 1.748 0 000 .088zm0 .088v1.05a9.816 1.748 0 009.553 1.711 9.816 1.748 0 0010.07-1.699 9.816 1.748 0 00.004-.057h0v-1.058h0a9.816 1.748 0 01-.003.057 9.816 1.748 0 01-10.071 1.699 9.816 1.748 0 01-9.553-1.703z"
          fill="#ffe6d5"
          fillOpacity={0.3}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M33.974 76.246a9.816 1.748 0 000 .088zm0 .088v1.05a9.816 1.748 0 009.553 1.711 9.816 1.748 0 0010.07-1.699 9.816 1.748 0 00.004-.057h0v-1.058h0a9.816 1.748 0 01-.003.057 9.816 1.748 0 01-10.071 1.7 9.816 1.748 0 01-9.553-1.704z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath1154"
          fill="#ffe6d5"
          fillOpacity={0.3}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M51.14 74.504c-.076-.006-.156.003-.215-.07-.052-.545-.36-.313-.721-.186-.548.247-.675-.13-.94-.503a2 2 0 01-.513.492 9.816 1.748 0 012.216.316z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath3290"
          fill="#ffd5d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <g
          transform="matrix(.42294 0 0 .47809 29.644 39.45)"
          id="SvgCakeG3248"
          strokeWidth={0}
          stroke="#000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeOpacity={1}
        >
          <path
            d="M32.222 73.516l-.26.26.553-.27.219.26.157-.24.59.214-.34-.569-.771.034z"
            id="SvgCakePath3244"
            fill="green"
          />
          <path
            d="M31.656 72.477s.175-.81.354-1.057c.174-.24.638-.62.638-.62s.483.371.661.614c.185.253.402.85.402.85s-.007 1.069-.402 1.276c-.19.1-.614-.189-.614-.189s-.378.202-.543.118c-.4-.203-.496-.992-.496-.992z"
            id="SvgCakePath3246"
            fill="#ff2a2a"
            fillOpacity={1}
          />
        </g>
        <g
          transform="translate(20.61 5.282)"
          id="SvgCakePiece"
          strokeWidth={0}
          strokeDasharray="none"
          ref={SvgCakePiece}
        >
          <path
            id="SvgCakePath1018"
            d="M30.356 69.27l-6.639 1.105 9.308.085a9.816 1.748 0 00-2.669-1.19z"
            opacity={0.997}
            fill="#a40"
            fillOpacity={1}
            stroke="#a7832d"
            strokeLinecap="round"
            strokeMiterlimit={4}
            strokeOpacity={1}
          />
          <path
            d="M23.717 70.375v4.233l9.308.113v-4.26z"
            id="SvgCakePath1036"
            fill="#fea"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit={4}
            strokeOpacity={1}
          />
          <path
            id="SvgCakePath3288"
            style={{
              mixBlendMode: 'normal',
            }}
            d="M30.564 69.212l-.173.049a9.816 1.748 0 011.159.27c-.099-.827-.609-.287-.986-.319zm1.709.089c-.155.001-.418.218-.657.248a9.816 1.748 0 011.327.665c.032.008.023-.031-.064-.167-.022-.505-.354-.061-.476-.36.027-.296-.029-.387-.13-.386z"
            fill="#ffd5d5"
            fillOpacity={1}
            stroke="#2d5ea7"
            strokeLinecap="round"
            strokeMiterlimit={0}
          />
          <path
            d="M29.977 69.726l-.11.124.234-.129.092.125.067-.115.249.102-.143-.272-.327.016z"
            id="SvgCakePath3232"
            fill="green"
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit={4}
            strokeOpacity={1}
          />
          <path
            d="M29.737 69.23s.074-.388.15-.506c.074-.115.27-.297.27-.297s.204.178.28.294c.078.12.17.407.17.407s-.004.51-.17.61c-.08.047-.26-.09-.26-.09s-.16.096-.23.056c-.169-.097-.21-.475-.21-.475z"
            id="SvgCakePath1218"
            fill="#ff2a2a"
            fillOpacity={1}
            stroke="#000"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit={4}
            strokeOpacity={1}
          />
          <path
            id="SvgCakeRect3314"
            fill="#fca"
            fillOpacity={1}
            stroke="#a7832d"
            strokeLinecap="round"
            strokeMiterlimit={4}
            strokeOpacity={1}
            d="M23.718 71.036h9.331v.874h-9.331z"
          />
          <path
            id="SvgCakeRect3316"
            fill="#fca"
            fillOpacity={1}
            stroke="#a7832d"
            strokeLinecap="round"
            strokeMiterlimit={4}
            strokeOpacity={1}
            d="M23.703 73.11h9.331v.874h-9.331z"
          />
        </g>
        <path
          id="SvgCakePath1166"
          style={{
            mixBlendMode: 'normal',
          }}
          d="M53.6 75.764a9.816 1.748 0 01-.003.054 9.816 1.748 0 01-10.071 1.7 9.816 1.748 0 01-9.552-1.697v4.13a9.816 1.748 0 009.554 1.781A9.816 1.748 0 0053.6 80.033a9.816 1.748 0 00.003-.02 9.816 1.748 0 000-.003 9.816 1.748 0 000-.049v-4.197z"
          fill="#ffb380"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M33.986 75.666a9.816 1.748 0 00-.01.042l-.002.009a9.816 1.748 0 009.552 1.79 9.816 1.748 0 0010.072-1.698 9.816 1.748 0 00.003-.057l-9.307-.086zm19.603.007a9.816 1.748 0 01.005.02 9.816 1.748 0 00-.005-.02z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath1168"
          fill="#a40"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={4}
          strokeDasharray="none"
        />
        <path
          d="M33.974 78.363a9.816 1.748 0 000 .088zm0 .088v1.05a9.816 1.748 0 009.553 1.711 9.816 1.748 0 0010.07-1.699 9.816 1.748 0 00.004-.057h0v-1.058h0a9.816 1.748 0 01-.003.057 9.816 1.748 0 01-10.071 1.699 9.816 1.748 0 01-9.553-1.703z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath1170"
          fill="#ffe6d5"
          fillOpacity={0.3}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          id="SvgCakePath1172"
          style={{
            mixBlendMode: 'normal',
          }}
          d="M33.974 76.246a9.816 1.748 0 000 .088zm0 .088v1.05a9.816 1.748 0 009.553 1.711 9.816 1.748 0 0010.07-1.699 9.816 1.748 0 00.004-.057h0v-1.058h0a9.816 1.748 0 01-.003.057 9.816 1.748 0 01-10.071 1.7 9.816 1.748 0 01-9.553-1.704z"
          fill="#ffe6d5"
          fillOpacity={0.3}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <g
          transform="matrix(.42294 0 0 .47809 25.982 41.771)"
          id="SvgCakeG3272"
          strokeWidth={0}
          stroke="#000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeOpacity={1}
        >
          <path
            d="M32.222 73.516l-.26.26.553-.27.219.26.157-.24.59.214-.34-.569-.771.034z"
            id="SvgCakePath3268"
            fill="green"
          />
          <path
            d="M31.656 72.477s.175-.81.354-1.057c.174-.24.638-.62.638-.62s.483.371.661.614c.185.253.402.85.402.85s-.007 1.069-.402 1.276c-.19.1-.614-.189-.614-.189s-.378.202-.543.118c-.4-.203-.496-.992-.496-.992z"
            id="SvgCakePath3270"
            fill="#ff2a2a"
            fillOpacity={1}
          />
        </g>
        <path
          d="M34.353 75.595c-.108-.001-.243.044-.38.095v.131a9.816 1.748 0 004.572 1.441.726.726 0 01-.081-.084c-.187-.956-1.046.13-1.481-.54-.153-1.139-.781.699-1.144-.08-.108-.866-.991.035-1.252-.509.036-.346-.07-.452-.234-.454zm19.114.027c-.162-.01-.278.103-.226.42-.071.756-.593-.358-.854.044.017.777-.942.765-1.355.348-.386.567-.976.783-1.523.214-.155.088-.53.637-.986.577-.523-1.138-1.022.517-1.663-.019-.449-.603-.829.08-1.284.16-.44.146-.98-.769-1.24-.152-.564.874-1.061-.673-1.614.014-.39.61-.739-.017-.982-.359-.513.1-1.261 1.02-1.598.156-.295-.235-.652.136-1.012.299a9.816 1.748 0 004.397.208 9.816 1.748 0 0010.07-1.699 9.816 1.748 0 00.004-.057h0v-.119a.461.461 0 00-.134-.035z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath1185"
          fill="#ffe6d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M33.995 75.66c-.003.003-.007.004-.01.006l.009-.002a9.816 1.748 0 010-.004z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath1187"
          fill="#ffd5d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M36.43 74.212c-.443-.04-.657.798-1.247.585-.48-.37-.588.056-.76.433a9.816 1.748 0 012.515-.733.595.595 0 01-.158-.092c-.134-.127-.248-.184-.35-.193z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath3302"
          fill="#ffd5d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M38.126 73.884c-.299-.041-.516.355-.782.546a9.816 1.748 0 011.327-.174c-.123-.018-.236-.096-.325-.267a.452.452 0 00-.22-.105z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath3300"
          fill="#ffd5d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          d="M39.75 73.746c-.202.08-.543.396-.86.487a9.816 1.748 0 011.544-.128.95.95 0 01-.684-.36z"
          style={{
            mixBlendMode: 'normal',
          }}
          id="SvgCakePath3298"
          fill="#ffd5d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <path
          id="SvgCakePath1209"
          style={{
            mixBlendMode: 'normal',
          }}
          d="M34.353 75.595c-.108-.001-.243.044-.38.095v.131a9.816 1.748 0 004.572 1.441.725.725 0 01-.081-.084c-.187-.956-1.046.13-1.481-.54-.153-1.139-.781.699-1.144-.08-.108-.866-.991.035-1.252-.509.036-.346-.07-.452-.234-.454zm19.114.027c-.162-.01-.278.103-.226.42-.071.756-.593-.358-.854.044.017.777-.942.765-1.355.348-.386.567-.976.783-1.523.214-.155.088-.53.637-.986.577-.523-1.138-1.022.517-1.663-.019-.449-.603-.829.08-1.284.16-.44.146-.98-.769-1.24-.152-.564.874-1.061-.673-1.614.014-.39.61-.739-.017-.982-.359-.513.1-1.261 1.02-1.598.156-.295-.235-.652.136-1.012.299a9.816 1.748 0 004.397.208 9.816 1.748 0 0010.07-1.699 9.816 1.748 0 00.004-.057h0v-.119a.46.46 0 00-.135-.035z"
          fill="#ffe6d5"
          fillOpacity={1}
          stroke="#2d5ea7"
          strokeWidth={0}
          strokeLinecap="round"
          strokeMiterlimit={0}
          strokeDasharray="none"
        />
        <g
          id="SvgCakeG3254"
          transform="matrix(.42294 0 0 .47809 26.448 39.539)"
          strokeWidth={0}
          stroke="#000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeOpacity={1}
        >
          <path
            id="SvgCakePath3250"
            d="M32.222 73.516l-.26.26.553-.27.219.26.157-.24.59.214-.34-.569-.771.034z"
            fill="green"
          />
          <path
            id="SvgCakePath3252"
            d="M31.656 72.477s.175-.81.354-1.057c.174-.24.638-.62.638-.62s.483.371.661.614c.185.253.402.85.402.85s-.007 1.069-.402 1.276c-.19.1-.614-.189-.614-.189s-.378.202-.543.118c-.4-.203-.496-.992-.496-.992z"
            fill="#ff2a2a"
            fillOpacity={1}
          />
        </g>
        <g
          transform="matrix(.42294 0 0 .47809 22.972 40.556)"
          id="SvgCakeG3260"
          strokeWidth={0}
          stroke="#000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeOpacity={1}
        >
          <path
            d="M32.222 73.516l-.26.26.553-.27.219.26.157-.24.59.214-.34-.569-.771.034z"
            id="SvgCakePath3256"
            fill="green"
          />
          <path
            d="M31.656 72.477s.175-.81.354-1.057c.174-.24.638-.62.638-.62s.483.371.661.614c.185.253.402.85.402.85s-.007 1.069-.402 1.276c-.19.1-.614-.189-.614-.189s-.378.202-.543.118c-.4-.203-.496-.992-.496-.992z"
            id="SvgCakePath3258"
            fill="#ff2a2a"
            fillOpacity={1}
          />
        </g>
        <g
          id="SvgCakeG3266"
          transform="matrix(.42294 0 0 .47809 29.7 41.718)"
          strokeWidth={0}
          stroke="#000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeOpacity={1}
        >
          <path
            id="SvgCakePath3262"
            d="M32.222 73.516l-.26.26.553-.27.219.26.157-.24.59.214-.34-.569-.771.034z"
            fill="green"
          />
          <path
            id="SvgCakePath3264"
            d="M31.656 72.477s.175-.81.354-1.057c.174-.24.638-.62.638-.62s.483.371.661.614c.185.253.402.85.402.85s-.007 1.069-.402 1.276c-.19.1-.614-.189-.614-.189s-.378.202-.543.118c-.4-.203-.496-.992-.496-.992z"
            fill="#ff2a2a"
            fillOpacity={1}
          />
        </g>
        <g
          transform="matrix(.42294 0 0 .47809 34.524 41.4)"
          id="SvgCakeG3284"
          strokeWidth={0}
          stroke="#000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={4}
          strokeDasharray="none"
          strokeOpacity={1}
        >
          <path
            d="M32.222 73.516l-.26.26.553-.27.219.26.157-.24.59.214-.34-.569-.771.034z"
            id="SvgCakePath3280"
            fill="green"
          />
          <path
            d="M31.656 72.477s.175-.81.354-1.057c.174-.24.638-.62.638-.62s.483.371.661.614c.185.253.402.85.402.85s-.007 1.069-.402 1.276c-.19.1-.614-.189-.614-.189s-.378.202-.543.118c-.4-.203-.496-.992-.496-.992z"
            id="SvgCakePath3282"
            fill="#ff2a2a"
            fillOpacity={1}
          />
        </g>
        <image
          href='""'
          id="SvgCakeTarget"
          height={11}
          width={11}
          x={85.834}
          y={81.189}
          strokeWidth={33.761}
          ref={SvgCakeTarget}
        />
      </g>
    </svg>
  )
})
SvgCake.displayName = 'SvgCake'
export default SvgCake
