import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

const Loading = ({ text, size }) => {
  return (
    <Container>
      <Spinner size={size} />
      {text && <Label>{text}</Label>}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #798f9c;
  align-items: center;
  display: flex;
  flex-direction: column;
`
const Spinner = styled(CircularProgress)`
  margin-top: auto;
`
const Label = styled.h1`
  margin-bottom: auto;
  align-items: center;
`

Loading.propTypes = {
  text: PropTypes.string,
  size: PropTypes.number,
}

Loading.defaultProps = {
  size: 100,
}

export default Loading
