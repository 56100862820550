import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const IntroDialog = ({ open, setOpen, text }) => {
  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{t('attention')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t(text)}
        </DialogContentText>
        <img
          style={{ display: 'block', margin: '0 auto' }}
          src="/imgs/personal/arturoHelp.png"
          alt="arturo"
          width="100"
          height="100"
        ></img>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

IntroDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  text: PropTypes.string,
}

export default IntroDialog
