import React, { useState, useEffect, useContext } from 'react'
import Jitsi from 'react-jitsi'
import { useParams } from 'react-router-dom'
import { Page } from '../components/common/styles'
import EventTimer from '../components/event/EventTimer'
import { AuthContext } from '../contexts/authContext'
import styled from 'styled-components'
import Loading from '../components/common/Loading'
import { useTranslation } from 'react-i18next'
import Devices from '../shared/Devices'
import useWindowDimensions from '../hooks/useWindowDimensions'

const toolbarButtons = [
  'camera',
  'chat',
  'fullscreen',
  'hangup',
  'help',
  'microphone',
  'raisehand',
  'select-background',
  'settings',
  'stats',
  'toggle-camera',
  'videoquality',
  '__end',
]

const Event = () => {
  const { t } = useTranslation()
  const { width, height } = useWindowDimensions()
  const { authState } = useContext(AuthContext)
  const { id } = useParams()
  const [eventData, setEventData] = useState(undefined)
  const [isCallReady, setIsCallReady] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [jitsiApi, setJitsiApi] = useState()

  const handleAPI = (JitsiMeetAPI) => {
    if (authState.usrAttr.profile.isAdmin) {
      setJitsiApi(JitsiMeetAPI)
      JitsiMeetAPI.executeCommand('setFollowMe', true)
    }
  }

  useEffect(() => {
    console.log(`Event with id ${id}`)
    // Temporary hack to put the event in the profile data
    setEventData(authState.usrAttr.profile.event)
  }, [id, setEventData])

  const customLoading = () => {
    return <Loading size={50} text="Waiting for call to start" />
  }

  return (
    <Page>
      {eventData ? (
        isCallReady ? (
          <CallContainer>
            <Title>Arturo Cumple su primer año!</Title>
            <Jitsi
              roomName={eventData.roomName}
              displayName={authState.usrAttr.profile.name}
              password={eventData.password}
              loadingComponent={customLoading}
              config={{
                prejoinPageEnabled: false,
                toolbarButtons: authState.usrAttr.profile.isAdmin
                  ? undefined
                  : toolbarButtons,
              }}
              domain={eventData.domain}
              onAPILoad={handleAPI}
              containerStyle={{
                boxShadow: '10px 10px 10px grey, 0 0 10px black',
                width: width * 0.8,
                height: height * 0.5,
              }}
            />
          </CallContainer>
        ) : (
          <EventTimer
            startTime={eventData.dateTime}
            onTime={() => setIsCallReady(true)}
          />
        )
      ) : (
        <Loading text={t('Loading')} />
      )}
    </Page>
  )
}

const CallContainer = styled.div`
  margin-left: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  /* background-color: #8ce9c2; */
  background-image: url('/imgs/partyBkg.jpg');
  display: flex;
  flex-direction: column;
`
const Title = styled.h1`
  font-family: 'Walter Turncoat', cursive;
  text-shadow: 2px 2px #707070;
  color: #4d3574;
  font-size: 1.5em;
  margin: 0 0 5% 0;
  @media ${Devices.laptop} {
    font-size: 3em;
  }
`

export default Event
