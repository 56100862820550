import React, { useState, useCallback } from 'react'
import { Tween, ScrollTrigger } from 'react-gsap'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import Devices from '../../shared/Devices'

const AnimatedPicture = ({ src, frames, trigger }) => {
  const [offset, setOffset] = useState(0)
  const frameCount = frames - 1
  const imageRef = useCallback((node) => {
    if (node !== null) {
      setOffset(node.getBoundingClientRect().width / 2)
    }
  }, [])
  return (
    <>
      {offset === 0 || frames === 1 ? (
        <MovingImage ref={imageRef} src={src} frames={frames} />
      ) : (
        <ScrollTrigger
          trigger={trigger}
          start="top top"
          end={'+=' + frameCount * offset}
          scrub={true}
          // markers
        >
          <Tween
            to={{
              backgroundPosition: '100% 100%',
              ease: 'steps(' + frameCount + ')',
            }}
          >
            <MovingImage src={src} frames={frames} />
          </Tween>
        </ScrollTrigger>
      )}
    </>
  )
}

AnimatedPicture.propTypes = {
  src: PropTypes.string,
  frames: PropTypes.number,
  trigger: PropTypes.string,
}

const MovingImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  background-image: ${(props) => `url(${props.src})`};
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: ${(props) => `${props.frames * 100}%`};
  flex-grow: 3;
  @media ${Devices.laptop} {
    font-size: 4em;
  }
  @media (orientation: landscape) {
    height: 100%;
    width: auto;
  }
  @media (orientation: portrait) {
    height: auto;
    width: 100%;
  }
`

export default AnimatedPicture
