import { PropTypes } from 'prop-types'
import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InfoIcon from '@material-ui/icons/Info'
import CakeIcon from '@material-ui/icons/Cake'
import SearchIcon from '@material-ui/icons/Search'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
})
const SideMenu = ({ openMenu, setMenuOpen }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const navigateTo = (url) => {
    setMenuOpen(false)
    history.push(url)
  }

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem
          button
          onClick={() => {
            navigateTo('/easter_hunt')
          }}
        >
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary={t('EasterHunt')} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            navigateTo('/birthday/first')
          }}
        >
          <ListItemIcon>
            <CakeIcon />
          </ListItemIcon>
          <ListItemText primary={t('FirstBirthday')} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            navigateTo('/about')
          }}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary={t('AboutUs')} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            navigateTo('/admin/users')
          }}
        >
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary={t('Administration')} />
        </ListItem>
      </List>
    </div>
  )
  return (
    <Drawer anchor="left" open={openMenu} onClose={() => setMenuOpen(false)}>
      {list()}
    </Drawer>
  )
}
SideMenu.propTypes = {
  openMenu: PropTypes.bool,
  setMenuOpen: PropTypes.func,
}

export default SideMenu
