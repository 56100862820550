/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Stage, Text, Container as PixiContainer } from '@inlet/react-pixi'
import Transition from '../pixi/Transition'
import Baby from '../pixi/Baby'
import { useHistory } from 'react-router-dom'

const timeThresholds = {
  GONE: -2 * 60 * 60000,
  NOW: 60000,
  ALMOST: 5 * 60000,
  MINUTES: 60 * 60000,
  HOURS: 24 * 60 * 60000,
}

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const EventTimer = ({ startTime, onTime }) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [timeLeft, setTimeLeft] = useState('')
  const [bkgColor, setBkgColor] = useState({ backgroundColor: 0x50e6e6 })
  const updateTimer = useRef(null)
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 })
  const [isResized, setResized] = useState(true)
  const [userClicked, setUserClicked] = useState(false)
  const history = useHistory()

  const container = useCallback(
    (node) => {
      if (node !== null && isResized) {
        const newSize = {
          width: node.getBoundingClientRect().width,
          height: node.getBoundingClientRect().height,
        }
        if (
          newSize.width !== canvasSize.width ||
          newSize.height !== canvasSize.height
        ) {
          setCanvasSize(newSize)
        }
      }
    },
    [isResized, setResized]
  )

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setCanvasSize({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 200)

    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const secondsToMessage = (seconds) => {
    seconds = Number(seconds)
    var d = Math.floor(seconds / (3600 * 24))
    var h = Math.floor((seconds % (3600 * 24)) / 3600)
    var m = Math.floor((seconds % 3600) / 60)
    var s = Math.floor(seconds % 60)

    var dDisplay = d > 0 ? d + ' ' + t('Day', { count: d }) : ''
    var hDisplay = d + h > 0 ? h + ' ' + t('Hour', { count: h }) : ''
    var mDisplay = d + h + m > 0 ? m + ' ' + t('Minute', { count: m }) : ''
    var sDisplay = d + h + m + s > 0 ? s + ' ' + t('Second', { count: s }) : ''
    return dDisplay + ' ' + hDisplay + ' ' + mDisplay + ' ' + sDisplay
  }

  const checkMeetingTime = () => {
    if (!startTime) {
      setMessage(t('NoDateEvent'))
      setBkgColor('white')
      return
    }
    const diff = new Date(startTime) - new Date()
    if (diff < timeThresholds.GONE) {
      setMessage(t('EventTooLate'))
      setBkgColor({ backgroundColor: 0x7f0505 })
      return
    } else if (diff < timeThresholds.NOW && diff > timeThresholds.GONE) {
      setMessage(t('CallingEvent'))
      setBkgColor({ backgroundColor: 0x206f10 })
      onTime()
      return
    } else if (diff < timeThresholds.ALMOST) {
      setMessage(t('AlmostEvent'))
      setBkgColor({ backgroundColor: 0x602010 })
    } else if (diff < timeThresholds.MINUTES) {
      setMessage(t('MinutesToEvent'))
    } else if (diff < timeThresholds.HOURS) {
      setMessage(t('HoursToEvent'))
    } else {
      setMessage(t('DaysToEvent'))
      setBkgColor({ backgroundColor: 0x50e6e6 })
    }

    setTimeLeft(secondsToMessage(diff / 1000))
  }

  useEffect(() => {
    if (updateTimer.current) {
      clearInterval(updateTimer.current)
    } else {
      checkMeetingTime()
    }
    updateTimer.current = setInterval(() => {
      checkMeetingTime()
    }, 1000)
  }, [startTime])

  useEffect(() => {
    return () => {
      if (updateTimer.current) {
        clearInterval(updateTimer.current)
      }
    }
  }, [])

  const handleAnimationClick = () => {
    setTimeout(() => {
      setUserClicked(true)
    }, 1500)
  }

  return (
    <Container ref={container} /* color={bkgColor} */>
      <Stage
        options={bkgColor}
        width={canvasSize.width || 0}
        height={canvasSize.height || 0}
        renderOnComponentChange={true}
      >
        <PixiContainer>
          <Text
            anchor={0.5}
            y={canvasSize.height * 0.25}
            x={canvasSize.width / 2}
            text={message}
            style={{
              fontFamily: "'Slackey', cursive",
              fontSize: Math.min(canvasSize.height, canvasSize.width) * 0.08,
              wordWrap: true,
              wordWrapWidth: canvasSize.width * 0.95,
              align: 'center',
            }}
          />
          <Text
            anchor={0.5}
            y={canvasSize.height * 0.5}
            x={canvasSize.width / 2}
            text={timeLeft}
            style={{
              fontFamily: "'Orbitron', sans-serif",
              fontSize: Math.min(canvasSize.height, canvasSize.width) * 0.04,
              wordWrap: true,
              wordWrapWidth: canvasSize.width * 0.95,
              align: 'center',
            }}
          />
          <Baby
            action="sit"
            initX={canvasSize.width * 0.5}
            initY={canvasSize.height * 0.65}
            onClick={() => handleAnimationClick()}
          />
          <Text
            anchor={0.5}
            y={canvasSize.height * 0.7}
            x={canvasSize.width / 2}
            text={t('Game1.PlayWithBaby')}
            style={{
              // fontFamily: "'Orbitron', sans-serif",
              fontFamily: "'Slackey', cursive",
              fontSize: Math.min(canvasSize.height, canvasSize.width) * 0.03,
              wordWrap: true,
              wordWrapWidth: canvasSize.width * 0.95,
              align: 'center',
            }}
          />
          {userClicked && (
            <Transition
              isDone={() => {
                history.push('/game/1')
              }}
            />
          )}
        </PixiContainer>
      </Stage>
    </Container>
  )
}

EventTimer.propTypes = {
  startTime: PropTypes.string,
  onTime: PropTypes.func,
}

const Container = styled.div`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const Message = styled.div`
  font-family: 'Slackey', cursive;
  font-size: 2em;
`

const Time = styled.h2`
  font-family: 'Orbitron', sans-serif;
  font-size: 1em;
`

export default EventTimer
