import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { AuthContext } from '../../contexts/authContext'
import { PropTypes } from 'prop-types'

const useStyles = makeStyles({
  root: {
    maxWidth: '400px',
    margin: 'auto',
    paddingTop: '100px',
  },
  media: {
    height: '200px',
  },
})

const FinishDashboard = ({ score, onRestart }) => {
  const { authState } = useContext(AuthContext)
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="/imgs/personal/happyArturo.jpg"
          title="HappyArturo"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {t('YouFound') + score.toString() + t('Eggs')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t('FinishedMsg', {
              name: authState.usrAttr.profile.name,
              relationship: authState.usrAttr.profile.relationship,
              haveYou: t('HaveYou', { count: authState.usrAttr.profile.count }),
            })}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => onRestart()}>
          {t('Restart')}
        </Button>
      </CardActions>
    </Card>
  )
}

FinishDashboard.propTypes = {
  score: PropTypes.number,
  onRestart: PropTypes.func,
}

export default FinishDashboard
