import { useState, useEffect } from 'react'
import { useApp } from '@inlet/react-pixi'

export default function usePixiCanvasSize() {
  const app = useApp()
  const [width, setWidth] = useState(app.renderer.view.clientWidth)
  const [height, setHeight] = useState(app.renderer.view.clientHeight)

  useEffect(() => {
    setWidth(app.renderer.view.clientWidth)
    setHeight(app.renderer.view.clientHeight)
  }, [app.renderer.view.clientWidth, app.renderer.view.clientHeight])

  return { width, height }
}
