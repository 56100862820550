import styled from 'styled-components'

export const Wrapper = styled.div`
  .FourOhFour {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #121212;
    display: flex;
    align-items: center;
    justify-content: center;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-size: cover;
      mix-blend-mode: overlay;
    }

    .code {
      font-family: 'Alfa Slab One', cursive;
      font-size: 144px;
      height: 100vh;
      color: white;
      width: 100%;
      display: flex;
      backgorund-position: center;
      align-items: center;
      background-size: cover;
      justify-content: center;
    }
  }
`
export default Wrapper
