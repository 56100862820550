import React from 'react'
import { useTranslation } from 'react-i18next'
import { Panel, PageHeader, TextBox } from '../components/birthday/PanelStyles'
import { Page } from '../components/common/styles'

const AboutUs = () => {
  const { t } = useTranslation()
  return (
    <Page>
      <Panel>
        <PageHeader>{t('AboutUs')}</PageHeader>
        <TextBox maxWidthLandscape="70%">
          {t("We are Arturo's parents, doing!")}
        </TextBox>
      </Panel>
    </Page>
  )
}

export default AboutUs
