// import { useTranslation } from "react-i18next";
import EasterHunt from './pages/easter_hunt'
import Users from './pages/users'
import Login from './pages/login'
import FirstBirthday from './pages/firstBirthday'
import Event from './pages/event'
import AboutUs from './pages/aboutUs'
import PlayGame from './pages/playGame'
import PageNotFound from './pages/pageNotFound'
import SideMenu from './components/SideMenu'
import ScrollToTop from './components/common/ScrollToTop'
import Loading from './components/common/Loading'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import { AuthContext } from './contexts/authContext'
import { AppContext } from './contexts/appContext'
import React, { useContext, useEffect, useState } from 'react'
import MenuBar from './components/MenuBar'
import { GetCurrentSession } from './services/authentication'
import { useTranslation } from 'react-i18next'
import './App.css'

function App() {
  const { t, i18n } = useTranslation()
  const { authState, setAuthState } = useContext(AuthContext)
  const { displayTopBar } = useContext(AppContext)
  const [ready, setReady] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    GetCurrentSession()
      .then((auth) => {
        setAuthState({
          isAuth: true,
          usrAttr: { ...auth.usrAttr },
          token: auth.token,
        })
        i18n.changeLanguage(auth.usrAttr.locale)
      })
      .catch(() => {})
      .finally(() => {
        setReady(true)
      })
  }, [setReady, setAuthState, i18n])

  return (
    <>
      {ready ? (
        <>
          {authState.isAuth && displayTopBar && (
            <MenuBar onMenuOpen={() => setDrawerOpen(true)}></MenuBar>
          )}
          <div style={{ width: '100%', height: '100%' }}>
            <Router>
              <ScrollToTop />
              {authState.isAuth && (
                <SideMenu openMenu={drawerOpen} setMenuOpen={setDrawerOpen} />
              )}
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/">
                  <Switch>
                    <ProtectedRoute exact path="/" component={FirstBirthday} />
                    <ProtectedRoute
                      exact
                      path="/birthday/first"
                      component={FirstBirthday}
                      style={{ height: '100%' }}
                    />
                    <ProtectedRoute
                      exact
                      path="/admin/users"
                      component={Users}
                    />
                    <ProtectedRoute path="/events/:id" component={Event} />
                    <ProtectedRoute exact path="/about" component={AboutUs} />
                    <ProtectedRoute
                      exact
                      path="/game/:id"
                      component={PlayGame}
                    />
                    <ProtectedRoute
                      exact
                      path="/easter_hunt"
                      component={EasterHunt}
                    />
                  </Switch>
                </Route>
                <Route path="/imgs/*" component={PageNotFound} />
                <Route component={PageNotFound} />
              </Switch>
            </Router>
          </div>
        </>
      ) : (
        <Loading text={t('Loading')} />
      )}
    </>
  )
}

export default App
