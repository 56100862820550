import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useStyles from './login.style'
import { LogUserIn, DoUserPswChallenge } from '../services/authentication'
import { AuthContext } from '../contexts/authContext'
import { useTranslation } from 'react-i18next'
import Modal from '@material-ui/core/Modal'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.onesockpirates.com/">
        OneSockPirates
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

function getModalStyle() {
  const top = 50
  const left = 47

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    padding: '3%',
    margin: '3%',
  }
}

export default function Login() {
  const { authState, setAuthState } = useContext(AuthContext)
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword1, setNewPassword1] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const { t, i18n } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const [session, setSession] = useState()
  const [modalStyle] = useState(getModalStyle)
  const [newPswError, setNewPswError] = useState()

  const handleOpenModal = (session) => {
    setSession(session)
    setOpenModal(true)
  }

  const handleCloseModal = (event) => {
    event.preventDefault()
    DoUserPswChallenge(session, email, newPassword1, newPassword2).then(
      () => {
        setSession(null)
        setOpenModal(false)
        setNewPswError(undefined)
      },
      (err) => {
        setNewPswError(err)
      }
    )
  }

  const doLogin = (event) => {
    event.preventDefault()
    LogUserIn(email, password).then(
      (auth) => {
        setAuthState({
          isAuth: true,
          usrAttr: { ...auth.usrAttr },
          token: auth.token,
        })
        i18n.changeLanguage(auth.usrAttr.locale)
      },
      (err) => {
        if (err.reason === 'newPassword') {
          handleOpenModal(err.session)
        } else {
          console.log('There was an error:', err)
        }
      }
    )
  }

  return (
    <>
      {authState.isAuth ? (
        <Redirect to="/" />
      ) : (
        <>
          <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
            >
              <div className={classes.paper}>
                {/* <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar> */}
                <img
                  src="/imgs/logo.svg"
                  alt="osp"
                  className={classes.logoSmall}
                ></img>
                <Typography component="h1" variant="h5">
                  {t('OSP')}
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={t('Username')}
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('Password')}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => {
                      doLogin(e)
                    }}
                  >
                    {t('SignIn')}
                  </Button>
                  {/* <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="#" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid> */}
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </form>
              </div>
            </Grid>
          </Grid>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.modal}>
              <form
                onSubmit={handleCloseModal}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <h2 id="simple-modal-title">{t('UpdatePsw')}</h2>
                {!session && (
                  <TextField
                    id="old_psw"
                    label={t('CurrentPsw')}
                    color="secondary"
                    type="password"
                  />
                )}
                <TextField
                  id="psw1"
                  label={t('NewPsw')}
                  color="secondary"
                  type="password"
                  onChange={(e) => setNewPassword1(e.target.value)}
                />
                <TextField
                  id="psw2"
                  label={t('RepeatPsw')}
                  color="secondary"
                  type="password"
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
                {newPswError && <p>{newPswError.message}</p>}
                <br />
                <Button variant="contained" color="primary" type="submit">
                  {t('Update')}
                </Button>
              </form>
            </div>
          </Modal>
        </>
      )}
    </>
  )
}
