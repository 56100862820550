/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Stage, Text, Container as PixiContainer } from '@inlet/react-pixi'
import Game from '../components/pixi/Game'
import StartHelp from '../components/pixi/StartHelp'
import { AppContext } from '../contexts/appContext'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { useHistory } from 'react-router-dom'

const PlayGame = () => {
  const handle = useFullScreenHandle()
  const { width, height } = useWindowDimensions()
  const [messageDone, setMessageDone] = useState(false)
  const [assetsReady, setAssetsReady] = useState(false)
  const { hideTopBar, showTopBar } = useContext(AppContext)
  const [key, setKey] = useState(0)
  const history = useHistory()

  useEffect(() => {
    try {
      handle.enter().catch((e) => console.log('Fullescreen exception'))
    } catch (e) {
      console.log(e)
    }
    hideTopBar()
    return () => {
      try {
        handle.exit().catch((e) => console.log('could not exit fullscreen'))
      } catch (e) {
        console.log(e)
      }
      showTopBar()
    }
  }, [])

  const handleReturn = () => {
    history.push('/events/1')
  }

  const handleRestart = () => {
    console.log('Restart issued')
    setKey((x) => x + 1)
    setAssetsReady(false)
  }

  const handleLoaded = () => {
    console.log(messageDone)
    setAssetsReady(true)
  }

  return (
    <FullScreen handle={handle}>
      <Stage
        options={{ backgroundColor: 0x50e6e6 }}
        width={width}
        height={height}
        renderOnComponentChange={true}
      >
        <Game
          key={key}
          start={messageDone && assetsReady}
          onExit={() => handleReturn()}
          onRestart={() => handleRestart()}
          onLoaded={() => handleLoaded()}
        />
        {(!messageDone || !assetsReady) && (
          <PixiContainer>
            <StartHelp
              isDone={() => {
                // setKey((x) => x + 1)
                setMessageDone(true)
              }}
            />
          </PixiContainer>
        )}
      </Stage>
    </FullScreen>
  )
}

export default PlayGame
