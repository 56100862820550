import React, { createElement, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../contexts/authContext'
import { PropTypes } from 'prop-types'
import Loading from './common/Loading'

const ProtectedRoute = ({ component }) => {
  const { authState } = useContext(AuthContext)

  return (
    <>
      {authState ? (
        <Route
          render={() =>
            authState.isAuth ? (
              createElement(component)
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      ) : (
        <Loading />
      )}
    </>
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.func,
}

export default ProtectedRoute
