import Wrapper from './pageNotFound.style'
import React from 'react'

const PageNotFound = () => {
  return (
    <Wrapper>
      <div className="FourOhFour">
        <div
          className="bg"
          style={{
            backgroundImage: 'url(http://i.giphy.com/l117HrgEinjIA.gif)',
          }}
        ></div>
        <div className="code">404</div>
      </div>
    </Wrapper>
  )
}

export default PageNotFound
